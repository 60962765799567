.loginScreen{
  min-height: 100vh;
  width:100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1976d2!important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #1976d2!important;
}



.loginScreen__body {
  z-index: 1;
  width:100%;
  margin: 100px 0;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginScreen__body > * {
  width: 50%;
  padding: 0 60px;
}
.loginScreen__body h2 {
  margin-bottom: 5px;
}
@media only screen and (max-width: 800px){
  .loginScreen__body {
    flex-direction: column;
  }
  .loginScreen__body > div {
    padding: 10px 20px;
    text-align:center;
    width: calc(100% - 40px);
  }
}
@media only screen and (max-width: 680px) {
  .loginScreen__body {
    width: calc(100% - 60px);
    padding: 20px 30px;
  }
  .loginScreen__body h2, .loginScreen__body p {
    text-align:center;
  }
}
@media only screen and (max-width: 468px){
  .loginScreen__body {
    padding: 20px 15px;
    width: calc(100% - 30px);
  }
}




.loginScreen__input > form > input {
  padding: 10px;
  width: 200px;
  outline-width: 0;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
}

.loginScreen__bodyScroll {
  position: absolute;
  top: 70vh;
  width:100%;
}
.loginScreen__bodyScroll > * {
  display: inline-block;
  vertical-align:middle;
}
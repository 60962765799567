
.authorPopup {
   width: fit-content;
   left: 0;
   z-index: 99999;
   display: inherit;
 }
 .authorPhotoWrapper {
   width: 200px;
   height: 200px;
   min-width:200px;
   display:flex;
   justify-content:center;
   align-items:center;
   z-index:99;
 }
 .authorPhoto {
   width:90%;
   height:90%;
   background-size:cover;
   border-radius:100%;
 }

 .authorContent {
   padding: 30px 30px 30px 80px;
   margin-left: -70px;
   background-color:#fff;
   border-radius: 5px;
 }
 .authorPopup > div > * {
  color: #000;
  display: inline-block;
 }
 .authorPopup span{
   color: #999;
 }
 .authorPopup p {
   display:block;
   margin-bottom: 5px;
 }
 .authorSocial {
   margin-right: 15px;
 }


 @media only screen and (max-width:600px){
   .authorPopup {
     display: block;
   }
   .authorPhotoWrapper {
     width: 100px!important;
     min-width:100px;
     height:100px;
     margin: 0 auto;
   }
   .authorContent {
     margin-left:0;
     margin-top: -80px;
     padding:90px 30px 30px 30px;
   }
   .close__mobilePaddingTop {
     margin-top:30px!important;
   }
 }

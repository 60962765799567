.vidProgressBar {
  width: calc(100% - 40px);
  height: 10px;
  background-color: #ddd;
  margin: 0 20px;
  margin-bottom: 20px;
}
.vidProgressBar:hover {
  cursor: pointer;
}
.vidProgressBarDone {
  width: 0;
  height: 10px;
  background-color: #1976d2;
}

.femTestDataWrapper {
  padding: 130px 60px;
}
.femTestDataTableWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  overflow: scroll;
  margin-top: 60px;
}
.femTestDataTableWrapper > div {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  width: fit-content;
}
.femTestDataTableWrapper > div:last-child {
  border-bottom: none;
}
.femTestDataTableWrapper > div > div {
  width: 100px;
  flex: 1;
  padding: 10px;
  padding-right: 20px;
}
.femTestDataTableWrapper > div > div:nth-child(2) {
  padding-left: 150px;
}
.femTestDataTableWrapper > div > div:last-child {
  padding-right: 10px;
}
.femTestDataTableWrapper > div:hover {
  background-color: #acd6ff !important;
  z-index: 99;
}

.loadingWrapper {
  padding: 50px;
}
.breakAll {
  word-break: break-all;
}
.breakWord {
  word-break: normal;
}
.columnFreeze {
  position: fixed;
  background-color: #fff;
  border-right: 1px solid #333;
}

.sfaAgreementWrapper {
  border: 1px solid #ddd;
  padding: 30px;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 600px;
  max-height: 450px;
  overflow: scroll;
}

.sfaAgreementWrapper h2 {
  padding-top: 20px;
  padding-bottom: 5px;
}
.sfaAgreementWrapper h2:first-child {
  padding-top: 0;
}
.sfaAgreementWrapper p {
  margin-bottom: 10px;
}

.membersScreen__hero {
  padding: 160px 60px 0 60px;
  text-align:center;
} 
@media only screen and (max-width: 680px){
  .membersScreen__hero{
    background-size: cover;
    padding: 120px 30px 0 30px;
  }
}
@media only screen and (max-width:480px) {
  .membersScreen__hero {
    padding: 100px 15px 0 15px;
  }
}

.membersScreen__subtitle {
  text-align:center;
  padding: 0 60px;
  margin: 0 auto;
}

.membersScreen__members {
  padding: 80px 0;
}
.membersScreen__membersWrapper {
  padding: 40px 30px;
}
.membersScreen__membersWrapper > p {
  text-align: center;
  margin-bottom: 40px;
}
.membersScreen__memberList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}
.membersScreen__member {
  width: calc(25% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin-bottom: 40px;
  align-items: center;
  text-align:center;
  padding: 0 10px;
}
.membersScreen__member > img{
  max-width: 200px;
  width:100%;
  border-radius: 50%;
  display: block;
  margin-bottom: 10px;
}
.membersScreen__member span {
  
  color: #666;
}
.membersScreen__memberJoin {
  width: calc(25vw - 30px);
  height: calc(25vw - 30px);
  max-width: 200px;
  max-height: 200px;
  background-color: #f7f7f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  
}
.membersScreen__memberJoin img {
  width: 79%;
  padding-top:10px;
  padding-left:5px;
  opacity: 0.2;
}
@media only screen and (max-width: 680px) {
  .membersScreen__subtitle {
    padding: 0 30px;
  }
  .membersScreen__membersWrapper {
    margin: 0 auto;
    padding: 40px 20px;
  }
  .membersScreen__member {
    flex-wrap: wrap;
    width: calc(50% - 20px)!important;
    padding:10px;
  }
  .membersScreen__member > img {
    max-width:35vw;
    max-height:35vw;
  }
  .membersScreen__memberJoinWrapper {
    width: 100%!important;
    padding: 30px 0!important;
    background: #f7f7f7!important;
    border-radius: 7px;
  }
  .membersScreen__memberJoin{
    display: none!important;
  }
  .membersScreen__memberJoin p {
    font-size: 18px!important;
  }
  .membersScreen__memberJoin > span{
    content: ""!important;
  }
}
@media only screen and (max-width:468px) {
  .membersScreen__subtitle {
    padding: 0 15px;
  }
  .membersScreen__members {
    padding-top: 40px;
  }
  .membersScreen__membersWrapper {
    /* padding-top: 0; */
    padding-bottom: 0;
  }
}





.membersScreen__partner {
  margin: 0 60px 60px 60px;
  padding: 80px 60px;
  background-color: #f2f6fb;
  text-align: center;
  border-radius: 7px;
}
.membersScreen__partnerLogos {
  width: 100%;
  display: flex;
  justify-content: center;

}
.membersScreen__partnerLogos img {
  height: 30px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.membersScreen__partnerLogos > img:first-of-type {
  border-right: 1px solid #666;
  height: 25px;
}
@media only screen and (max-width: 680px) {
  .membersScreen__partner {
    margin: 0 30px 30px 30px!important;
    padding: 20px!important;
  }
  .membersScreen__partnerLogos {
    flex-direction: column;
  }
  .membersScreen__partnerLogos img {
    width: 80%;
    padding: 10px !important;
    border-right:0!important;
    height: auto!important;
    display:block;
    margin: 0 auto!important;
  }
  .membersScreen__partnerLogos > img{
    width: 60%!important;
    padding-top:30px!important;
  }
  .membersScreen__partner p {
    margin:30px 0;
  }
}
@media only screen and (max-width: 468px) {
  .membersScreen__partner {
    margin-left:15px!important;
    margin-right:15px!important;
  }
}

.profileScreen {
  min-height: 100vh;
  background-color:#ededed;
  padding: 160px 60px 100px 60px;
  display: flex;
  align-items: left;
}
.profileScreen__body {
  display: flex;
  flex-direction: column;
  background-color:#fff;
  /* box-shadow: 2px 4px 12px rgb(0 0 0 / 8%); */
  height: fit-content;
  margin:10px auto;
  padding: 40px 60px;
  padding-bottom: 60px;
  border-radius: 4px;
  position: sticky;
  top: 100px;
}



.profileScreen__info {
  display:flex;
}
.profileScreen__info > img{
  display: block;
  width: 360px;
}
.profileScreen__details {
  text-align:center;
}
.profileScreen__photoWrapper {
  width: 150px;
  height:150px;
  margin:0 auto;
  margin-bottom:50px;
}
.profileScreen__photo {
  width:100%;
  height:100%;
  background-size: cover;
  background-position:center;
  border-radius:100%;
}
.profileScreen__profile {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align:left;
}
.profileScreen__profileUpdateInput {
  width: calc(50% - 60px);
  margin: 0 30px 30px 30px;
}
.profileScreen__profile > p {
  width: calc(50% - 20px);
  padding-right: 20px;
  margin-bottom: 20px;
}
.profileScreen__profile span {
  
  color: #999;
  display: block;
}
.profileScreen__profile input {
  margin-top:5px;
  width: calc(100% - 20px);
  padding: 15px 10px;
  font-size: 1rem;
}




.profileScreen__data {
  width:65%;
}



@media only screen and (max-width: 800px) {
  .profileScreen__info {
    display: block;
  }
  .profileScreen__body {
    width: calc(100% - 100px);
    position:unset;
  }
  .profileScreen__profile {
    display: flex;
    flex-wrap: wrap;
  }
  .profileScreen__info{
    flex-direction: column;
  }
  .profileScreen__profile > * {
    width: calc(100% - 20px)!important;
  }
  .profileScreen__profileUpdateInput {
    margin-left: 0;
    margin-right: 0;
  }
  .profileScreen__data {
    width: 100%
  }
}

@media only screen and (max-width:680px){
  .profileScreen{
    min-height:100vh;
    height:fit-content;
    padding: 120px 0;
  }
  .profileScreen__body{
    margin: 0 30px;
    padding-left: 30px;
    padding-right:30px;
  }
}
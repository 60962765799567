.footer {
  background-color: #000;
  text-align:center;
  padding: 40px 0;
  width: calc(100%);
  color: #fff;
}

.footer__nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0 60px;
  width: fit-content;
  margin: 0 auto;
  text-align:center;
}

.footer__nav p {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 25px;
}
.footer__nav p:hover {
  color: #000!important;
}
.footer__company img {
  width: 120px;
  margin-top:80px;
  margin-bottom:10px;
}
.footer__company span {
  
  display: block;
}


@media only screen and (max-width: 1300px) {
  .footer__nav p {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 1000px) {
  .footer__nav p {
    margin: 0 10px;
    width: calc(33% - 40px);
  }
}
@media only screen and (max-width: 850px) {
  .footer__nav {
    text-align: left;
  }
  .footer__nav p {
    padding: 10px;
  }
}
@media only screen and (max-width: 560px) {
  .footer__nav {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
    width: calc(100% - 30px);
  }
  .footer__nav p {
    margin: 0 10px;
    width: calc(50% - 40px);
  }
  .footer__company img {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 360px) {
  .footer__nav p {
    margin: 0 10px;
    display:block;
    width: 80%;
  }
}

.icon3dStyle img {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}
.scoreResult {
  font-weight: bold;
  color: #1976d2;
}

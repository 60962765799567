.dataSummary {
}
.dataSummary__categories {
  background-color:#fff;
  border-radius: 4px;
  margin:10px;
  padding: 40px;
}





.dataSummary__allCategoryProgress {
  margin-top: 20px;
}
.dataSummary__progressTitle {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.dataSummary__progressTitle > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dataSummary__progressTitle > div > span{
  
  padding-right: 10px;
}


/* Each category bar data */
.dataSummary__allCategoryProgressBar {
  margin-bottom:10px;
}
.dataSummary__allCategoryProgressBar p {
  
  border-left:1px solid #000;
  padding-left: 5px;
}
.dataSummary__allCategoryProgressBarBg {
  width:100%;
  background-color: #f7f7f7;
  height:10px;
  text-align:right;
}

.dataSummary__allCategoryProgressBarData {
  /* background-color: #000; */
  background-color: #1976d2;
  height:100%;
}
.dataSummary__allCategoryProgressBarDataContent.show{
  padding-bottom: 10px;
}
.dataSummary__allCategoryProgressBarDataContent.hide {
  display: none;
}
.dataSummary__allCategoryProgressBarDataContent span {
  padding-left: 5px;
  padding-right: 10px;
  color: #999;
  padding-top:3px;
  display: inline-block;
  
}
.privacyPolicyScreen {
  padding: 100px 60px 100px 60px;
  background-color: #f7f7f7;
}
.privacyPolicyScreen * {
  word-wrap: break-word;
}
.privacyPolicyScreen strong {
  font-weight:bold;
}
.privacyPolicyScreen h1 {
  font-size: 1.7em;
  padding-top:60px;
}
.privacyPolicyScreen h2 {
  padding-top: 20px;
}
.privacyPolicyScreen h3 {
  padding-top: 10px;
}
.privacyPolicyScreen ul {
  margin-top:15px;
  margin-bottom:15px;
}
.privacyPolicyScreen li {
  margin-top: 10px;
  margin-bottom: 10px;
}
.privacyPolicyScreen a {
  color: #1976d2;
}
.privacyPolicyScreen li, .privacyPolicyScreen > p {
  font-size: 0.9em;
}
.privacyPolicyScreen .smallText {
}

@media only screen and (max-width: 680px) {
  .privacyPolicyScreen {
    padding: 60px 30px 100px 30px;
  }
}
@media only screen and (max-width: 480px) {
  .privacyPolicyScreen {
    padding-left: 15px;
    padding-right: 15px;
  }
}
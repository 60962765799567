.aboutScreen__aboutWrapper {
  padding: 160px 60px 50px 60px;
  width: calc(100% - 120px);
  max-width: 960px;
  z-index: 9999;
  display: flex;
  margin: 0 auto;
  align-items: center;
}
.aboutScreen__aboutItem {
  width:100%;
  text-align:center;
}
@media only screen and (max-width: 1000px) {
  .aboutScreen__aboutWrapper {
    flex-direction: column;
    padding-bottom:60px;
  }
}
@media only screen and (max-width: 480px){
  .aboutScreen__aboutWrapper {
    padding-bottom:0px;
  }
}





.aboutScreen__feature {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin:0 auto;
  padding: 0px 20px 100px 20px;
}
.aboutScreen__featurePoint {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right:1px solid #ddd;
  text-align:center;
}
.aboutScreen__featurePoint:last-child {
  border: 0;
}
.aboutScreen__featureCircle {
  /* box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
  background-color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom:20px;
}
.aboutScreen__featureCircle > * {
  font-size: 1.3em!important;
  color: #1976d2!important;
}
.aboutScreen__featurePoint h2, .aboutScreen__featurePoint p {
  margin-bottom: 20px;
  padding: 0 60px;
}
@media only screen and (max-width: 1200px) {
  .aboutScreen__feature{
    flex-wrap: wrap;
  }
  .aboutScreen__featurePoint {
    /* flex-direction: row; */
    width: calc(50% - 20px);
    padding: 40px 0;
  }
  .aboutScreen__featurePoint:first-child, .aboutScreen__featurePoint:nth-child(2) {
    border-bottom: 1px solid #ddd;
  }
  .aboutScreen__featurePoint:nth-child(even) {
    border-right: 0;
  }
  .aboutScreen__featurePoint h2, .aboutScreen__featurePoint p {
    padding: 0 30px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 680px) {
  .aboutScreen__featurePoint {
    /* flex-direction: row; */
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 480px){
  .aboutScreen__feature {
    padding-bottom: 40px;
  }
  .aboutScreen__featurePoint {
    width: calc(100% - 30px)!important;
    border-right: 0;
    border-bottom: 1px solid #ddd;
  }
}









.aboutScreen__story {
  display: flex;
  align-items: center;
  margin: 0 auto;
  position:relative;
  text-align: center;
}
.aboutScreen__storyContent {
  width:100%;
  margin-top:20px;
  margin: 0 auto;
  background-color: #f2f6fb;
  padding: 40px 60px;
  display: flex;
}
.aboutScreen__storyText {
  max-width: 960px;
  margin: 0 auto;
}
.aboutScreen__story h2 {
  margin-bottom: 20px;
  text-align: center;
}
.aboutScreen__storyTextSmall {
  padding-top: 40px;
  border-top: 1px solid #ddd;
  margin-top: 40px;
  font-size: 0.6em;
  line-height: 1.2em;
  display: block;
  color: #666;
  text-align: left;
}
@media only screen and (max-width: 1400px) {
  .aboutScreen__storyContent {
    flex-direction: column;
  }
  .aboutScreen__storyText {
    width: 100%;
    border: 0;
  }
  
}
@media only screen and (max-width: 1100px) {
  .aboutScreen__story img {
    display: none;
  }
}
@media only screen and (max-width:850px) {
  
}
@media only screen and (max-width: 680px) {
  .aboutScreen__storyContent {
    padding: 60px 40px;
  }
  
}
@media only screen and (max-width:480px){
  .aboutScreen__storyContent {
    padding: 60px 30px;
  }
}





.aboutScreen__experience {
  display: flex;
  align-items: center;
  padding: 40px 0;
  justify-content: center;
}
.aboutScreen__experience video {
  border: 1px solid #ddd;
  margin: 60px;
  max-width: 40%;
  pointer-events: none;
}
@media only screen and (max-width: 1000px) {
  .aboutScreen__experience {
    flex-direction: column;
    padding-top: 0;
  }
  .aboutScreen__experience video {
    width: calc(100% - 40px);
    max-width: 700px;
  }
}

.aboutScreen__testimonial {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  margin-right:60px;
}
.aboutScreen__testimonial img{
  width:100px;
  height: 100px;
  border-radius: 50%;
}
.aboutScreen__testimonialText {
  margin-left: 30px;
  max-width: 750px;
}
.aboutScreen__testimonialText span{
  
  color: #666;
}
.aboutScreen__testimonialText svg{
  color: #1976d2!important;
}
@media only screen and (max-width: 1000px) {
  .aboutScreen__testimonial {
    padding:0 60px;
    margin: 0;
  }
}
@media only screen and (max-width: 680px) {
  .aboutScreen__testimonial{
    flex-direction: column;
    padding: 0 30px;
  }
  .aboutScreen__testimonialText {
    margin: 0!important;
    position:relative;
    padding-top:20px;
  }
  .aboutScreen__testimonialText svg{
    position: absolute;
    top: -30px;
    right: 0;
  }
}
.bannerTestAno {
  padding: 120px 60px 60px 60px;
  background-color:#f7f7f7;
  border-bottom: 1px solid #ddd;
  margin-bottom: 100px;
}
.bannerTestAno h2 {
  margin-bottom: 20px;
}
.bannerTestAno p {
  margin-bottom: 10px;
}
@media only screen and (max-width: 680px) {
  .bannerTestAno {
    padding: 100px 30px 60px 30px;
    margin-bottom: 60px;
  }
}
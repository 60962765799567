.torsoFemaleView__markOneCardio {
  top: 39px;
  left: 74px;
}
.torsoFemaleView__markTwoCardio {
  top: 39px;
  left: 134px;
}
.torsoFemaleView__markThreeCardio {
  top: 84px;
  left:125px;
}
.torsoFemaleView__markFourCardio {
  top: 120px;
  left: 50px
}
.torsoFemaleView__markFiveCardio {
  top: 131px;
  left: 104px;
}
.torsoFemaleView__markSixCardio {
  top: 110px;
  left: 149px;
}
.torsoFemaleView__markSevenCardio {
  top: 140px;
  left: 159px;
}





@media only screen and (max-width: 680px) {
  .torsoFemaleView__markOneCardio {
    top: 25px;
    left: 44px;
  }
  .torsoFemaleView__markTwoCardio {
    top: 25px;
    left: 105px;
  }
  .torsoFemaleView__markThreeCardio {
    top: 61px;
    left:87px;
  }
  .torsoFemaleView__markFourCardio {
    top: 83px;
    left: 35px
  }
  .torsoFemaleView__markFiveCardio {
    top: 91px;
    left: 72px;
  }
  .torsoFemaleView__markSixCardio {
    top: 75px;
    left: 116px;
  }
  .torsoFemaleView__markSevenCardio {
    top: 102px;
    left: 112px;
  }
}
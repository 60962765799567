.loadingScreen{
  overflow:hidden;
}
.loadingScreen > div {
  width:100%;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingScreen img {
  display: block;
}
.loadingScreen h1 {
  text-align:center;
  line-height:0.8;
}
/* original css for text */
/* .loadingScreen__textColorAnimation
{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #000 0%,
    #1976d2 29%,
    #c789f1 67%,
    #ddd 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
      font-size: 190px;
} */


.loadingScreen__textColorAnimation
{
  background: linear-gradient(
    45deg,
    #000 0%,
    #1976d2 29%,
    #c789f1 67%,
    #ddd 100%
  );
  width: 200vw;
  background-size: 200%;
  background-position: 100vw;
  animation: textclip 1.7s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200vw center;
  }
}
.torsoView__markOneBowel {
  top: 182px;
  left: 78px;
}
.torsoView__markTwoBowel {
  top: 182px;
  left: 127px;
}
.torsoView__markThreeBowel {
  top: 222px;
  left: 79px;
}
.torsoView__markFourBowel {
  top: 222px;
  left: 124px
}
.torsoView__markFiveBowel {
  top: 286px;
  left: 74px
}




@media only screen and (max-width: 680px) {
  .torsoView__markOneBowel {
    top: 129px;
    left: 54px;
  }
  .torsoView__markTwoBowel {
    top: 131px;
    left: 92px;
  }
  .torsoView__markThreeBowel {
    top: 158px;
    left:56px;
  }
  .torsoView__markFourBowel {
    top: 158px;
    left: 91px
  }
  .torsoView__markFiveBowel {
    top: 206px;
    left: 44px
  }
}
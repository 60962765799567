.adminDashboard {
  padding: 120px 60px 60px 60px;
}
.adminDashboard h2 {
  text-align:center;
}
.toggleHideUnknown {
  /* width: fit-content; */
  text-align:right;
  margin-bottom:40px;
}


.allTakesDataWrapper {
  width: 100%;
  border: 1px solid #ddd;
  overflow-x: scroll;
}
.allTakesData {
  display: flex;
  padding: 10px 0;
  min-width:1200px;
}
.allTakesData:hover {
  background-color:#f7f7f7;
}
.allTakesDataLegend {
  border-bottom: 1px solid #ddd;
}
.allTakesDataLegend > *{
  font-weight:bold;
}
.allTakesData__index {
  width: 40px;
  padding-left: 30px;
  padding-right: 10px;
}
.allTakesData__userId, .allTakesData__userEmail {
  width: 20%;
  padding-left:10px;
  padding-right:10px;
  overflow: hidden;
}
.allTakesData__category, .allTakesData__block, .allTakesData__score, .allTakesData__time, .allTakesData__delete {
  width: 100px;
  text-align:center;
  padding-left:10px;
  padding-right:10px;
}
.allTakesData__delete {
  opacity: 0.4;
  cursor: pointer;
}
.allTakesData__delete:hover{
  opacity: 1;
}

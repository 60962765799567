.floatView {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 60px;
}


.torsoViewComponent {
  background-color:#000;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
/* .torsoViewComponent > * {
  width: 100%;
} */
.torsoView__torsoWrapper {
  position: relative;
  margin-left:30px;
}
.torsoView__torsoImg {
  height: 350px;
}
.torsoView__torsoImg img {
  display: block;
  height: 100%;
}
.torsoView__mark {
  z-index: 99;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position:absolute;
  border: 3px dotted #fff;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
.torsoView__mark.select {
  background-color: rgba(84, 55, 231, 0.5)
}
@media only screen and (max-width: 680px) {
  .torsoView__torsoWrapper {
    margin-left: 15px;
    width: auto;
  }
  .torsoView__markAlt {
    left: 60px!important;
  }
  .torsoView__torsoImg {
    height: 250px;
  }
  .torsoView__mark {
    width: 20px;
    height: 20px;
    border: 2px dotted #fff;
  }
  .torsoView__usviewWrapper {
    height: 250px!important;
  }
}
@media only screen and (max-width: 480px) {
  .torsoView__markAlt {
    top: 0!important;
    left: 0!important;
    bottom: auto!important;
  }
}




.torsoView__usviewWrapper{
  height: 350px;
  position: relative;
  width:100%;
  overflow: hidden;
}
.torsoView__usview {
  width: 100%;
  height:100%;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align:center;
  overflow: hidden;
}

.torsoView__usviewZoom  > *{
  position: absolute;
  color:#fff;
  font-size: 2em!important;
  bottom: 20px;
}
.torsoView__usview video {
  height: 100%;
  max-width: 100%;
  pointer-events: none;
}
.torsoView__markAlt {
  color: #fff;
  width: fit-content;
  position: absolute;
  display: flex;
  bottom: 0;
  left: 35%;
  font-size: 0.9em;
}
.torsoView__markAlt p {
  padding: 3px 10px;
  background-color:#efeeef;
  color:#999;
  cursor: pointer;
}
.torsoView__markAlt p.select {
  background-color: #1976d2;
  color:#fff;
}





.zoomView__popupWrapper {
  text-align:center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  padding-top:80px;
}
.zoomView__popupContainer {
  background-color:#000;
}
.zoomView__close {
  color:#fff;
  position: absolute;
  top:10px;
  right:20px;;
  text-decoration: underline;
  cursor: pointer;
  z-index: 999;
}
.zoomView__popup {
  display:flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 70vh;
  min-width: 80vw;
}
.zoomView__popup video {
  max-width: 100%;
  min-width:100%;
}
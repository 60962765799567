.contactUs h1{
  margin-bottom: 20px;
}
.contactUs form{
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin: 0 auto;
  text-align:left;
  font-weight:300;
}
.contactUs form > * {
  margin-bottom:20px;
}
.contactUs form label {
  color: #999;
  font-weight: 500;
  z-index: 2;
  background-color: #fff;
  padding: 0 3px;
  width: fit-content;
}
.contactUs form input, .contactUs form textarea {
  font-weight:500;
}
.contactUS__submit {
  width: fit-content;
  margin: 0 auto;
}
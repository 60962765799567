.blockList {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 60px;
}
.blockList__block {
  width: calc(20% - 60px);
  padding: 20px 20px 50px 20px;
  margin: 10px;
  box-shadow: 0 0 0 1px #ddd;
  border-radius: 4px;
  text-align:left;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.blockList__block:hover {
  background-color: #f2f6fb;
}
.blockList__blockCategory {
  
  color:#999;
  display:block;
  padding-bottom:5px;
}
.blockList__blockNumber {
  margin-bottom: 10px;
}
.blockList__blockButton {
  /* text-align:right; */
  position: absolute;
  bottom:20px;
  right:20px;
}
@media only screen and (max-width:1100px) {
  .blockList__block {
    width: calc(33.3% - 60px)
  }
}
@media only screen and (max-width:680px) {
  .blockList {
    padding: 0 20px;
  }
  .blockList__block {
    width: calc(50% - 60px)
  }
}
@media only screen and (max-width:468px) {
  .blockList {
    padding: 0 10px;
  }
  .blockList__block {
    margin: 5px;
    width: calc(50% - 50px)
  }
}


/* Difficulty */
.qLevelDot {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 100%;
}
.qEasyDot {
  background-color: yellowgreen
}
.qIntermediateDot {
  background-color: #f87537;
}
.qAdvancedDot {
  background-color: #000;
}
/* Tags */
/* If we want to show tags per block, active below */
/* .blockList__tags p {
  font-size: 0.6em;
  text-transform: capitalize;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 100px;
  display: inline-block;
  padding: 2px 5px;
  margin-right: 5px;
} */
/* If want to hide tags per block, active below */
.blockList__tags p {
  display: none;
}

/* Data Section */
.blockList__block span {
  display: block;
  
}
.blockList__takeData {
  margin-bottom: 10px;
}
.blockList__takeData.neverTaken {
  color: #999;
}
.torsoView__markOneBiliary {
  top: 177px;
  left: 84px;
}
.torsoView__markTwoBiliary {
  top: 159px;
  left: 113px;
}
.torsoView__markThreeBiliary {
  top: 199px;
  left:119px;
}





@media only screen and (max-width: 680px) {
  .torsoView__markOneBiliary {
    top: 128px;
    left: 58px;
  }
  .torsoView__markTwoBiliary {
    top: 118px;
    left: 84px;
  }
  .torsoView__markThreeBiliary {
    top: 149px;
    left:84px;
  }
}
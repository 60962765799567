.femQuestionProcessWrapper {
  height: fit-content;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
}
@media only screen and (max-width: 680px) {
  .femQuestionChoiceWrapper {
    flex-direction: column;
  }
  .femAnswer {
    margin: 0 !important;
    margin-bottom: 30px !important;
    width: calc(100% - 40px) !important;
  }
}

.femQuestionProcessText {
  width: min-content;
  padding: 0 10px;
  border-right: 1px solid #ddd;
  color: #1976d2;
  text-align: center;
}
.femQuestionProcessTextLink {
  padding: 0 10px !important;
}
.femQuestionProcessText p {
  white-space: nowrap;
  font-weight: 700;
  line-height: 1;
}
.femQuestionProcessText span {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  color: #999;
}
.femQuestionChoiceWrapper {
  padding-top: 20px;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}
.femQuestionChoiceWrapper > div {
  width: 100%;
}
.femQuestion {
  padding-bottom: 10px;
}

.femQchoicesWrapper {
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 1200px;
}
/* NEW STYLE FOR RADIO CHOICES */
.femQchoiceWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
/* Customize the label container for each question option */
.femQchoiceLabel {
  display: flex;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

/* Hide default radio button */
.femQchoiceLabel input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Custom radio button appearance */
/* Wrapper for the entire choice */
.femQchoiceWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ededed;
  background-color: #f9f9f9;
}

/* Label for the choice question */
.femQchoiceLabel {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Wrapper for Yes/No radio buttons */
.femQradioOptions {
  display: flex;
  gap: 20px;
  margin-left: 20px;
}

/* Individual radio label */
.femQradioLabel {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  padding-left: 25px;
  user-select: none;
}

/* Hide default radio button */
.femQradioLabel input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Custom radio button appearance */
.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #2196f3;
  border-radius: 50%;
}

/* Change background on hover */
.femQradioLabel:hover input[type="radio"] ~ .radioCheckmark {
  background-color: #f1f1f1;
}

/* Add background when the radio button is checked */
.femQradioLabel input[type="radio"]:checked ~ .radioCheckmark {
  background-color: #2196f3;
}

/* Style the indicator (the dot inside the radio button) */
.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

.femQradioLabel input[type="radio"]:checked ~ .radioCheckmark:after {
  display: block;
}

/* Dot inside the custom radio button */
.radioCheckmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Correct Answer - Green styling */
.femQradioLabel.correct {
  background-color: #e1f0d1; /* Light green */
  border-color: #4caf50; /* Green */
  color: #4caf50; /* Green for text */
}

/* Incorrect Answer - Red styling */
.femQradioLabel.incorrect {
  background-color: #f8e5e5; /* Light red */
  border-color: #f44336; /* Red */
  color: #f44336; /* Red for text */
}

/* Ensure checkmark also gets correct color */
.femQradioLabel.correct .radioCheckmark {
  border-color: #4caf50;
}

.femQradioLabel.incorrect .radioCheckmark {
  border-color: #f44336;
}

.femQradioLabel.correct .radioCheckmark:after {
  background-color: #4caf50; /* Green dot */
}

.femQradioLabel.incorrect .radioCheckmark:after {
  background-color: #f44336; /* Red dot */
}

/* NEW STYLES FOR RADIO CHOICES END */
.femQchoice input[type="checkbox"] {
  margin: 1em 0;
  position: absolute;
  visibility: hidden;
}
.femQchoicesWrapper input[type="checkbox"] + label {
  align-items: center;
  border: 1px solid #ededed;
  display: flex;
  margin-bottom: 5px;
  padding: 10px;
  color: #666;
}
.femQchoicesWrapper input[type="checkbox"]:checked + label {
  background-color: #f2f6fb;
  border-color: #1976d2;
  color: #000;
}

.femButtonWrapper {
  text-align: center;
}
.femAnswer {
  margin: 30px;
  border: 1px solid #ccc;
  padding: 20px;
}
.femAnswerBold {
  font-weight: bold;
}

/* result style */
/* Mark answer after submit */
.femCorrect input[type="checkbox"]:checked + label {
  background-color: #e1f0d1;
  border-color: #709e3f !important;
}
.femIncorrect input[type="checkbox"]:checked + label {
  background-color: #f8e5e5;
  border-color: #cb4949 !important;
}

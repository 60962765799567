.banner {
  position: relative;
  background-color:#000;
  padding-top: 65px;
  color:#fff;
  overflow: hidden;
  display: flex;
  /* margin-bottom: 100px; */
  justify-content: center;
  text-align: center;
}
.banner__bg img{
  width:100%;
  height:100%;
  position: absolute;
  right:0;
  width: auto;
  opacity: 0.7;
}

.banner__contents{
  z-index: 3;
  padding-top: 60px;
  padding-bottom:60px;
  /* height: 230px; */
  display: flex;
  align-items: center;
}
@media only screen and (max-width:1200px) {
  .banner__bg img {
    left: 50%;
    right: auto;
  }
}
@media only screen and (max-width:680px) {
  .banner{
    padding:0;
  }
  .banner__contents {
    flex-direction: column-reverse;
    height: auto;
    padding-bottom: 60px;
  }
  .banner__graphic img{
    max-width: 450px!important;
    min-width: 100px!important;
    margin-top: 0!important;
  }
}
@media only screen and (max-width:468px) {
  .banner {
    /* margin-bottom: 40px; */
  }
} 


.banner__contents > * {
  padding:0 60px;
}
.banner__title {
  font-size: 3em;
  font-weight: bold;
  padding-bottom: 0.3rem;
  margin-bottom:10px;
  display: block;
}
.banner__subtitle {
  margin-bottom: 10px;
}

.banner__graphic {
}
.banner__graphic img{
  display: block;
  width: 100%;
  max-width: 600px;
  margin-top:60px;
  min-width: 580px;
}

@media only screen and (max-width:1000px) {
  .banner__graphic img {
    max-width: 400px;
    min-width: 300px;
  }
}
@media only screen and (max-width:680px) {
  .banner__contents > * {
    margin-top: 50px;
  }
}
@media only screen and (max-width:468px) {
  .banner__contents > * {
    padding: 0 20px;
  }
}
.torsoView__markOneThoracic {
  top: 80px;
  left: 61px;
}
.torsoView__markTwoThoracic {
  top: 80px;
  left: 152px;
}
.torsoView__markThreeThoracic {
  top: 155px;
  left:31px;
}
.torsoView__markFourThoracic {
  top: 155px;
  left:177px;
}


@media only screen and (max-width: 680px) {
  .torsoView__markOneThoracic {
    top: 55px;
    left: 39px;
  }
  .torsoView__markTwoThoracic {
    top: 55px;
    left: 111px;
  }
  .torsoView__markThreeThoracic {
    top: 108px;
    left:21px;
  }
  .torsoView__markFourThoracic {
    top: 118px;
    left: 127px;
  }
}
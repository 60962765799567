.faqScreen {}

.faqScreen__faq {
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 160px 60px 100px 60px;
  position: relative;
  overflow: hidden;
  min-height:60vh;
}
.faqScreen__faqQs {
  position: relative;
  z-index:9;
  max-width: 750px;
  width: 70%;
}
.faqScreen__faqQs > h2 {
  padding-bottom: 20px;
}
.faqScreen__faqA {
  color: #666;
  padding: 0 15px 15px 15px;
}
.faqScreen__faqQs .css-i4bv87-MuiSvgIcon-root {
  fill: #1976d2!important;
}
.faqScreen__faqImg img{
  position: absolute;
  width:60%;;
  height: auto;
  top:0;
  right:0;
  opacity: 0.4;
}
@media only screen and (max-width: 1350px) {
  .faqScreen__faqImg img {
    height:100%;
    width: auto;
  }
}
@media only screen and (max-width:680px) {
  .faqScreen__faq {
    padding:100px 30px;
    align-items: flex-end;
  }
  .faqScreen__faqQs {
    width: 100%;
  }
}
.femView {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #000;
}
.femTorso {
  height: 350px;
}
.femTorso img {
  display: block;
  height: 100%;
}
.torsoToggleWrapper {
  position: relative;
}
.femToggleHandle {
  background-color: hsla(0, 0%, 100%, 0.4);
  border: 3px dotted #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  position: absolute;
  width: 25px;
  z-index: 99;
}
.femToggleHandle.selected {
  background-color: rgba(84, 55, 231, 0.5);
}

.luqHandle {
  top: 155px;
  left: 178px;
}
.ruqHandle {
  top: 155px;
  left: 28px;
}
.pelvicHandle {
  top: 285px;
  left: 101px;
}
@media only screen and (max-width: 680px) {
  .ruqHandle {
    top: 113px;
    left: 19px;
  }
  .luqHandle {
    top: 116px;
    left: 124px;
  }
  .pelvicHandle {
    top: 200px;
    left: 72px;
  }
}

.femUsViewWrapper {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.femUsViewWrapper > div {
  height: 100%;
  width: 100%;
}
.femUsViewWrapper video {
  height: 100%;
  max-width: 100%;
  pointer-events: none;
  max-height: 350px;
  display: block;
  margin: 0 auto;
}

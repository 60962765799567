.femExplanationContainer {
  padding: 20px 0;
}
.femExplanationToggle {
  display: inline-block;
  margin-top: 15px;
}
.femExplanationToggle button {
  border-right: 1px solid #999;
  padding: 2px 25px;
}
.femExpToggleOnStyle {
  border: none;
  border-bottom: 10px solid #1976d2;
  background: none;
  font-size: 18px;
  font-weight: bold;
}
.femExpToggleOffStyle {
  border: none;
  background: none;
  font-size: 18px;
  color: #1976d2;
}

.femExplanationContent {
  padding-top: 10px;
}
.femExplanationContent img {
  max-width: 100%;
  display: block;
  margin-bottom: 10px;
}

@media only screen and (max-width: 680px) {
  .femExplanationContent img {
    max-width: 80% !important;
    margin: 0 auto;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');




/* General style */
* {
  margin: 0;
  transition-timing-function: ease-in;
  transition: all 0.5;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
/* MUI font force */
.MuiBox-root, .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root, .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root, .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root, .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label, .css-1wc848c-MuiFormHelperText-root, .css-140puxv-MuiTypography-root-MuiDialogTitle-root, .css-d3wcwz-MuiTypography-root, .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root, .css-qivjh0-MuiStepLabel-label, .css-bdhsul-MuiTypography-root-MuiDialogTitle-root, .css-qfso29-MuiTypography-root-MuiDialogContentText-root, .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
}

/* MUI color to purple "COLOR" */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color:#1976d2!important;
}
/* MUI color to purple "BACKGROUND-COLOR" */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track, .css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color:#1976d2!important;
}

body {
  margin: 0;
  font-size: 18px;
  line-height:1.4;
  /* letter-spacing: 1rem; */
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h1 {
  line-height: 1.1;
  font-weight: 400;
  font-size: 60px;
}
h2 {
  line-height: 1.2;
}
.biggerText {
  font-size: 1.5em;
}
.smallText {
  font-size: 0.8em!important;
}
.fontPurple {
  color: #1976d2;
}
/* @media only screen and ( max-width: 1200px){
  h1{
    font-size: 80px;
  }
} */
/* @media only screen and ( max-width: 1000px){
  h1{
    font-size: 70px;
  }
} */
@media only screen and ( max-width: 680px){
  body {
    line-height:1.4;
    font-weight:400;
  }
  h1 {
    font-size: 60px;
  }
}
@media only screen and ( max-width: 468px){
  body {
    line-height:1.4;
    font-weight:400;
    font-size: 18px;
  }
  h1 {
    font-size: 45px;
  }
  .smallText {
    font-size: 0.9em;
  }
}


button.ub_solidPurple:disabled {
  background-color: #999;
  pointer-events: none;
}
button.ub_solidPurple:disabled:hover {
  background-color: #999;
  pointer-events: none;
  box-shadow: none;
}

button.ub_solidPurple{
  padding: 10px 20px;
  border-radius: 7px;
  background-color: #1976d2;
  color: #fff;
  border:0;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
}
button.ub_solidPurple:hover{
  box-shadow: 0 3px 5px 2px rgb(48 8 76 / 10%);
  background-color: #014a92;
  cursor: pointer;
}
.ub_solidPurple__secondary {
  padding: 10px 20px;
  border-radius: 7px;
  background-color: #ffffff;
  color: #1976d2;
  border:1px solid #1976d2!important;
  border-collapse: collapse;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  margin-right:15px;
  margin-left:15px;
}


button.ub_solidWhite{
  padding: 10px 20px;
  border-radius: 100px;
  background-color: white;
  color:#000;
  border:0;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  transition: .07s ease-in-out;
}
button.ub_solidWhite:hover{
  box-shadow: 0 3px 5px 2px rgb(48 8 76 / 10%);
  transition: .07s ease-in-out;
  opacity: 0.9;
}

.ub_textWhite{
  background-color: none;
  background: none;
  cursor: pointer;
  border:0;
  color: #fff;
  font-size: 18px;
  text-decoration: underline;
}
.ub_textPurple{
  background-color: none;
  background: none;
  cursor: pointer;
  border:0;
  color: #1976d2;
  font-size: 18px;
  text-decoration: underline;
}




/* Message page */
.centerMessage {
  min-height: calc(100vh - 444px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.thankYouContribution {
  background-color:#000;
  padding: 160px 60px;
  min-height: calc(100vh - 500px);
  background-image: url('https://ultrasoundbox.s3.amazonaws.com/img/loginbg.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
}
.thankYouContribution__bodyWrapper {
  background-color:#fff;
  max-width: 900px;
  margin:0 auto;
  padding: 60px;
  border-radius:10px;
  text-align:center;
}
.thankYouContribution__title svg {
  fill: #1976d2;
}
.thankYouContribution__title p {
  margin-bottom: 60px;
}
.thankYouContribution__bodyWrapper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color: #000;
}
.thankYouContribution__bodyWrapper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #1976d2;
}

.thankYouContribution__process p {
  padding: 60px;
}
.thankYouContribution__bodyWrapper button {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media only screen and (max-width: 680px) {
  .thankYouContribution {
    padding-left: 30px;
    padding-right: 30px;
  }
  .thankYouContribution__bodyWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 468px) {
  .thankYouContribution {
    padding-left: 15px;
    padding-right: 15px;
  }
  .thankYouContribution__processDetail {
    padding: 60px 0!important;
    text-align:left;
  }
}
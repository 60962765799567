.femSurveyDataWrapper {
  padding: 130px 60px;
}
.femSurveyDataTableWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  overflow: scroll;
  margin-top: 60px;
}
.femSurveyDataTableWrapper > div {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  width: fit-content;
}
.femSurveyDataTableWrapper > div:last-child {
  border-bottom: none;
}
.femSurveyDataTableWrapper > div > div {
  min-width: 200px;
  flex: 1;

  padding: 10px;
  padding-right: 20px;
}
.femSurveyDataTableWrapper > div > div:last-child {
  padding-right: 10px;
}
.femSurveyDataTableWrapper > div:hover {
  background-color: #acd6ff !important;
  z-index: 99;
}

.loadingWrapper {
  padding: 50px;
}
.breakAll {
  word-break: break-all;
}
.breakWord {
  word-break: normal;
}
.columnFreeze {
  position: fixed;
  background-color: #fff;
  border-right: 1px solid #333;
}

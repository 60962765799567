
.submitYoursScreen {
  min-height: calc(100vh - 450px);
  height:100%;
  background-color:#fff;
}
.submitYoursScreen__banner {
  padding:100px 0 60px 0;
  text-align:center;
  background-color: #f2f6fb;
}
.submitYoursScreen__banner h1 {
  padding: 0 60px;
}
@media only screen and (max-width:680px){
  .submitYoursScreen__banner {
    background-size: cover;
    padding: 100px 0 40px 0;
  }
  .submitYoursScreen__banner h1 {
    padding: 0 30px;
  }
}


.submitYoursScreen__intro {
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  align-items: center;
  justify-content: center;
  text-align:center;
}
.submitYourScreenSeal {
  width: 100px;
  display: block;
  -webkit-animation:spin 15s linear infinite;
  -moz-animation:spin 15s linear infinite;
  animation:spin 15s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
.submitYoursScreen__intro h2 {
  margin: 15px 0;
  max-width: 1200px;
}
.submitYoursScreen__intro p {
  margin-bottom: 10px;
  max-width: 1200px;
}
@media only screen and (max-width: 1000px) {
  .submitYoursScreen__intro p {
    width: 100%;
  }
  .submitYoursScreen__intro div {
    width: 100%;
  }
}
.submitYoursScreen__categorySelect {
  padding: 60px 60px 160px 60px;
  text-align:center;
  background-color:#fff;
}
.submitYoursScreen > form {
  margin: 0 auto;
  background-color:#fff;
  padding: 0 60px;
  display: inline-block;
}
.submitYoursScreen__categorySelect > h3 {
  padding-bottom: 10px;
}
.submitYoursScreen__categorySelect > span {
  max-width: 780px;
  margin: 0 auto;
  
  padding-bottom: 50px;
  display: block;
}
.submitYoursScreen__sectionCategory > div > div {
  width: 100%;
  max-width: 400px;
  text-transform: capitalize;
}
@media only screen and (max-width:680px){
  .submitYoursScreen__intro {
    padding: 0 30px;
  }
  .submitYoursScreen__categorySelect {
    padding: 40px 30px 100px 30px;
  }
}



.submitYoursScreen__categorySelectWIP {
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
}
.submitYoursScreen__categorySelectWIP img {
  width: 90%;
  max-width: 250px;
}
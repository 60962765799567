form {
  max-width: 600px;
  margin: 0 auto;
}
.surveySection {
  border-bottom: 1px solid #ccc;
  padding: 20px 10px;
}
.surveySection .markRequired {
  font-size: 12px;
  color: red;
  font-style: italic;
  padding: 5px;
}
legend {
  font-weight: bold;
  padding-bottom: 10px;
}

input[type="email"],
input[type="text"] {
  padding: 10px;
  margin-right: 10px;
  min-width: 200px;
}

textarea {
  width: 100%;
}

/* Customize the label (the container) */
.surveySectionLabel {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.surveySectionLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #2196f3;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.surveySectionLabel:hover input ~ .checkmark {
  background-color: #2195f357;
}

/* When the radio button is checked, add a blue background */
.surveySectionLabel input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.surveySectionLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.surveySectionLabel .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.buttonWrapper {
  padding: 30px 0;
  text-align: center;
}

/* Style dropdown select */
.dropdownSelect {
  font-size: 18px;
  padding: 7px 10px;
}

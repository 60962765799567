.susmitTestPage {
  margin-top: 100px;
}

/* Video style */
.videoControl {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn-color-paused {
  background-color: #1976d2;
}
.btn-color-playing {
  background-color: #234261;
}
.videoControlBttn {
  padding: 20px 10px;
  color: white;
  width: 200px;
  border-radius: 5px;
  text-align: center;
}
.videoControlBttn:hover {
  cursor: pointer;
}

.videoWrapper {
  display: flex;
}
.sus-videoSize video {
  max-width: calc(100% - 10px);
  margin: 5px;
}
.hiddenVid {
  display: none;
}

.vidTimelineWrapper {
  padding: 10px 20px;
  background-color: grey;
  position: relative;
}
.vidTimeline {
  padding: 7px;
  background-color: blue;
}
.vidTimelineHandle {
  padding: 7px 3px;
  background-color: yellow;
  position: absolute;
  top: 10px;
}

/* Carousel override */
.carousel.carousel-slider .control-arrow {
  padding: 15px !important;
  background-color: #999 !important;
}
.carousel .slide img {
  max-height: 600px !important;
  width: auto !important;
}
.carousel .slide p {
  font-size: 14px !important;
}

/* Question section styling */
.questionSectionWrapper {
  margin: 0 20px;
  padding: 20px;
}
.questionVignette {
  padding-bottom: 10px;
}
.questionContentWrapper {
  display: flex;
  flex-wrap: wrap;
}
.eachQuestion {
  border: 1px solid #ddd;
  width: calc(50% - 50px);
  padding: 20px;
}
.eachQuestionTitle {
  padding-bottom: 10px;
}
.questionChoice input[type="checkbox" i] {
  margin-right: 5px;
}

/* Checkbox styling */
/* Customize the label (the container) */
.choiceContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.choiceContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.customCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.choiceContainer input:checked ~ .customCheckmark {
  background-color: #2196f3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.customCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.choiceContainer input:checked ~ .customCheckmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.choiceContainer .customCheckmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.choiceContainer.marker-wrongAnswer input:checked ~ .customCheckmark:after {
  content: "X";
  border: 0;
  font-weight: 900;
  color: #fff;
  transform: rotate(0deg);
  top: 1px;
  left: 7px;
  font-size: 17px;
}

/* Check answers */
.checkAnswerButtn {
  background-color: #1976d2;
  margin: 20px auto;
}
.videoControlBttnDisabled {
  background-color: #666;
  pointer-events: none;
}
.explanationBackground {
  /* position: fixed;
  top: 0;
  left: 0; */
  /* background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  padding: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.explanationModal {
  position: fixed;
  bottom: 0;
  z-index: 9;
  margin-top: 100px;
  background-color: #fff;
  /* padding: 30px; */
  height: auto;
  overflow: scroll;
  width: 100%;
  max-height: 50vh;
  border-top: 2px solid #1976d2;
  box-shadow: 0 -3px 10px rgb(0 0 0 / 0.2);
  transition: 1s;
  bottom: 0;
}
.explanationModalContentWrapper {
  position: relative;
}
.explanationModalCloseBtn {
  color: #1976d2;
  text-decoration: underline;
  padding: 5px 10px;
}
.explanationModalCloseBtn:hover {
  cursor: pointer;
}
.explanationModalTitleOpened {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background-color: #fff;
  width: calc(100% - 80px);
}
.explanationModalTitleCollapsed {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  width: calc(100% - 80px);
}
.explanationContent {
  padding: 30px 0 80px 0;
}
.explanationImg img {
  max-height: 40vh;
  width: auto;
}
.explanationImg p {
  padding: 5px 40px 20px;
}
.explanationSectionWrapper {
  padding: 10px 40px 30px;
  margin-top: 10px;
}
.explanationSectionTitle {
  font-weight: bold;
  padding-bottom: 5px;
}
.scoreWrapper {
  background-color: #1976d2;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin: 0 40px;
  border-radius: 5px;
}
.scoreWrapper p {
  font-weight: bold;
}

/* MARK ANSWERS */
.eachQuesitonAnswer,
.eachQuesitonAnswer span {
  color: #1976d2;
  font-weight: bold;
  padding-bottom: 10px;
}
label.marker-correctAnswer {
  color: green;
}
.marker-correctAnswer input:checked ~ .customCheckmark {
  background-color: green;
}
.marker-correctAnswer .customCheckmark {
  background-color: green;
  color: green;
}
.marker-wrongAnswer input:checked ~ .customCheckmark {
  background-color: red;
}
.marker-wrongAnswer input:checked + .choiceContainer {
  color: red;
}
/* MOBILE */
@media only screen and (max-width: 680px) {
  .questionSectionWrapper {
    padding: 0;
  }
  .questionVignette {
    font-size: 18px;
    line-height: 1.3;
  }
  .carousel .slide img {
    width: 100% !important;
  }
  .carousel.carousel-slider .control-arrow {
    padding: 5px !important;
  }
  .carousel .thumbs-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .carousel .thumbs {
    padding-left: 0;
  }
  .eachQuestion {
    width: calc(100% - 50px);
  }
  .explanationModal {
    /* max-height: 70vh; */
    margin-top: 0;
    max-height: 500px;
  }
  .explanationModal h2 {
    font-size: 20px;
  }
  .explanationModalTitleOpened {
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
  .explanationModalTitleCollapsed {
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
  .explanationImg img {
    max-height: auto;
    max-width: calc(100% - 20px);
  }
  .explanationImg p {
    font-size: 12px;
    padding: 5px 20px 20px;
  }
  .explanationContent {
    padding: 20px 0 50px 0;
  }
  .explanationSectionWrapper {
    padding: 10px 20px 30px;
  }
  .explanationSectionWrapper p,
  .explanationSectionWrapper li {
    font-size: 16px;
  }
  .scoreWrapper {
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

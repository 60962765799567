.materialScreen__header {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  padding: 160px 60px 40px 60px;
  text-align: center;
}
.materialScreen > h2{
  padding-bottom: 10px;
}
@media only screen and (max-width: 680px) {
  .materialScreen__header {
    padding: 100px 30px 0 30px;
  }
  .materialScreen > h1, .materialScreen > p {
    padding-right:30px;
    padding-left:30px;
  }
}
@media only screen and (max-width:468px) {
  .materialScreen__header {
    margin-bottom: 0;
    padding: 100px 20px 0 20px;
  }
}





.materialScreen__cardWrapper {
  display: flex;
  padding: 60px 60px 20px 60px;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
}
.materialScreen__card {
  text-align:left;
  width: calc(33.3% - 42px);
  margin: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
}
.materialScreen__card:hover .materialScreen__cardImage{
  /* background-color: #f2f6fb; */
  opacity: 0.4;
}
.materialScreen__cardImage {
  background-size: cover;
  background-position: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  overflow:hidden;
}
.materialScreen__cardImage img {
  width:100%;
}
.materialScreen__cardText {
  padding: 0 30px;
  margin-top: 10px;
   z-index: 99;
   position: relative;
}
.materialScreen__cardText h2 {
  margin-bottom: 10px;
}
.materialScreen__cardText span {
  display: block;
  color: #999;
}
.materialScreen__cardAction {
  padding: 30px;
  /* position: absolute; */
  width:calc(100% - 60px);
  bottom: 0;
  right:0;
}

@media only screen and (max-width: 900px){
  .materialScreen__card {
    width: calc(50% - 42px);
  }
}

@media only screen and (max-width: 680px) {
  .materialScreen__cardWrapper {
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
  }
  .materialScreen__card {
    width: 100%;
    margin-right:0;
    margin-left: 0;
  }
}
@media only screen and (max-width:468px) {
  .materialScreen__cardWrapper {
    padding-left: 15px!important;
    padding-right: 15px!important;
  }
  .materialScreen__card {
    margin: 10px 0!important;
  }
}





.materialScreen__more {
  background-color: #f2f6fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1360px;
  border-radius: 7px;
  margin: 60px auto;
}
.materialScreen__more > * {
  width:100%;
}
.materialScreen__moreContent {
  width: 100%;
  max-width: 970px;
  padding: 40px 60px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}
.materialScreen__moreContent h1{
  margin-bottom: 10px;
}
.materialScreen__moreContent p {
  margin-bottom: 30px;
}

@media only screen and (max-width:1500px) {
  .materialScreen__more {
    margin: 60px 80px;
  }
}
@media only screen and (max-width: 680px) {
  .materialScreen__more{
    padding: 60px 30px;
    margin-left: 30px;
    margin-right: 30px;

  }
  .materialScreen__moreContent{
    padding:0!important;
    max-width:100%;
  }
}
@media only screen and (max-width: 468px){
  .materialScreen__more{
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom:30px;
  }
  .materialScreen__moreContent {
    max-width: 100%;
  }
}
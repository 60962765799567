.SfaContainer{
  min-height: calc(100vh - 140px);
  padding: 100px 60px;
}
.SfaContainer h1 {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 850px;
}
input[type="text"] {
  padding: 10px;
  margin-right: 10px;
  min-width: 200px;
}
@media only screen and ( max-width: 680px){
  .SfaContainer{
    padding: 100px 35px;
  }
  .SfaContainer h1 {
    margin-bottom: 15px;
  }
}
@media only screen and ( max-width: 468px){
  .SfaContainer{
    padding: 100px 18px;
  }
}

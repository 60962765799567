.submitYoursAd {
  margin: 30px 60px;
  background-color: #f2f6fb;
  border-radius: 7px;
  position: relative;
  display: flex;
  overflow: hidden;
}
.submitYoursAd__contentWrapper {
  max-width: 750px;
  margin: 0 auto;
  text-align:center;
  padding: 40px 60px;
  position: relative;
  z-index:9;
}
.submitYoursAd__contentWrapper h2 {
  display: block;
  margin-bottom: 10px;
}
.submitYoursAd__contentWrapper p {
  display: block;
  margin-bottom: 40px;
}
@media only screen and (max-width: 900px) {
  .submitYoursAd {
    margin-right: 30px;
    margin-left: 30px;
    height: fit-content;
  }
}
@media only screen and (max-width: 468px){
  .submitYoursAd {
    margin-right: 15px;
    margin-left: 15px;
  }
  .submitYoursAd__contentWrapper {
    max-width: 100%;
    padding:30px;
  }
}
.femAgreementComponent h1 {
  text-align: center;
  padding-bottom: 10px;
}
.femAgreementWrapper {
  border: 1px solid #ddd;
  padding: 30px;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 600px;
  max-height: 450px;
  overflow: scroll;
}

.femAgreementWrapper h2 {
  padding-top: 20px;
  padding-bottom: 5px;
}
.femAgreementWrapper h2:first-child {
  padding-top: 0;
}
.femAgreementWrapper p {
  margin-bottom: 10px;
}

@media only screen and (max-width: 468px) {
  .femAgreementWrapper {
    max-height: 450px;
  }
  .femAgreement__consentWrapper input[type="radio" i] {
    min-width: 15px;
    min-height: 15px;
  }
}

.femLanding,
.femContentWrapper {
  margin: 100px 60px;
}
.femContentWrapper h1 {
  text-align: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 680px) {
  .femLanding,
  .femContentWrapper {
    margin: 100px 30px;
  }
}
.femLanding_loginVidWrapper {
  display: flex;
  background-color: #f2f6fb;
}
.femLanding_loginVidWrapper > div {
  width: 100%;
}
.femLanding_loginRegisterBox {
  padding: 30px;
  text-align: center;
}
.femLanding_loginRegisterBox h1 {
  font-size: 40px;
  margin-bottom: 0 !important;
}
.femLoginRegister_toggleSelected {
  color: #1976d2;
  border-bottom: 4px solid #1976d2;
}
.femLoginRegister_toggleUnselected {
  color: #666;
}
.femLoginRegister_toggleUnselected:hover {
  cursor: pointer;
}
.femLanding_vid {
  background-color: #000;
  display: flex;
  align-items: center;
}
.femLanding_vid video {
  width: 100%;
}

@media only screen and (max-width: 1050px) {
  .femLanding_loginVidWrapper {
    flex-direction: column;
  }
  .femLanding_loginVidWrapper > div {
    width: auto;
  }
}

.femLanding_introP {
  padding: 30px 30px 60px;
  border-bottom: 1px solid #1976d2;
  margin-bottom: 60px;
}
.femLanding_introP p {
  padding-bottom: 20px;
}

/* Consent page */
.femAgreement__consentWrapper {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.femAgreement__consentWrapper input {
  margin-right: 10px;
}
.femAgreement__consentWrapper button {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
}

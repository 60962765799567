.landingScreen{
}

.landingScreen__hero {
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  min-height: 70vh;
  position: relative;
  /* background:linear-gradient(45deg, #c789f1 30%, #1976d2 90%); */
  background-image: url('https://ultrasoundbox.s3.amazonaws.com/img/hero-bg.jpg');
  color:#fff;
  text-align:left;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content:center;
  padding: 80px 0;
  overflow: hidden;
}
@media only screen and (max-width: 680px) {
  .landingScreen__hero {
    min-height: 70vh;
    text-align: left;
    align-items: start;
    padding: 0 30px;
    width: calc(100% - 60px);
  }
  .landingScreen__hero h1 {
    padding: 0!important;
  }
  .landingScreen__heroCta{
    padding:30px 0!important;
  }
}
.landingScreen__hero h1{
  line-height:1;
  padding: 0 60px;
}
.landingScreen__heroCta {
  padding: 30px 60px;
}

.landingScreen__section {
  position: relative;
}
.landingScreen__section img {
  width: 100%;
}
@media only screen and (max-width: 468px){
  .landingScreen__hero {
    padding: 0 15px;
    width: calc(100% - 30px);
    background-image: url('https://ultrasoundbox.s3.amazonaws.com/img/hero-bg.jpg'), linear-gradient(45deg, #c789f1 30%, #1976d2 90%);
    background-blend-mode: soft-light;
  }
  .landingScreen__hero h1{
    margin-top: 20vh;
  }
}


/* Section1 - about */
.landingScreen__section-about {
  padding: 100px 30px;
  max-width:1400px;
  margin: 0 auto;
}
.landingScreen__section-aboutItemWrapper {
  z-index: 9999;
  display: flex;
  align-items: center;
}
.landingScreen__section-aboutImage {
  width: 35%;
}
.landingScreen__section-aboutItem {
  max-width: 60%;
  margin-left: 40px;
  padding:20px 0;
}
@media only screen and (max-width: 1000px) {
  .landingScreen__section-aboutItemWrapper {
    flex-direction: column;
  }
  .landingScreen__section-aboutImage {
    width:100%;
    margin-top: -170px;
    max-width:600px;
  }
  .landingScreen__section-aboutItem {
    margin: 0;
    max-width:100%;
  }
}
@media only screen and (max-width: 468px){
  .landingScreen__section-about {
    padding-bottom: 60px;
    padding-right: 15px;
    padding-left: 15px;
  }
}





/* Section Plus - Bullets */
.landingScreen__sectionBullet {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin:0 auto;
  padding: 0 20px;
}
.landingScreen__sectionBulletPoint {
  width: 100%;
  margin: 10px;
  background-color: #1976d2;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 40px;
  border-radius: 7px;
}
.landingScreen__sectionBulletPoint img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.landingScreen__sectionBulletPoint h2{
  margin-top:-100px;
}
.landingScreen__sectionBulletPoint h2, .landingScreen__sectionBulletPoint p {
  margin-bottom: 20px;
  padding: 0 30px;
}
@media only screen and (max-width: 680px) {
  .landingScreen__sectionBullet {
    flex-direction: column;
    padding: 0;
  }
  .landingScreen__sectionBulletPoint {
    margin:10px 30px;
    width: calc(100% - 60px);
    overflow: hidden;
  }
  .landingScreen__sectionBulletPoint img {
    opacity: 0.2;
  }
  .landingScreen__sectionBulletPoint h2{
    margin-top: -300px;
  }
}
@media only screen and (max-width: 468px){
  .landingScreen__sectionBulletPoint {
    position: relative;
    height: 150px;
    margin-left: 15px!important;
    margin-right: 15px!important;
    width: calc(100% - 30px)!important;
  }
  .landingScreen__sectionBulletPoint img {
    position: absolute;
  }
  .landingScreen__sectionBulletPoint h2 {
    margin-top: 20px!important;
  }
}




/* Section3 - join us */
.landingScreen__section-joinus {
  background: linear-gradient(45deg, #c789f1 30%, #1976d2 90%);
  max-width: 1380px;
  border-radius: 7px;
  margin:10px auto;
  color: #fff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 160px;
}
.landingScreen__section-joinusWrapper {
  padding:60px 30px;
  z-index: 91;
}
.landingScreen__section-joinusWrapper h2 {
  margin-bottom: 40px;
  max-width: 60%;
}
.landingScreen__section-joinusWrapper p {
  margin-bottom: 10px;
  max-width: 60%;
}
.landingScreen__section-joinusImg {
  pointer-events: none;
  position: absolute;
  right: 0;
  height:100%;
}
.landingScreen__section-joinusImg img{
  display: block;
  height:100%;
  width: auto;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media only screen and (max-width: 1460px) {
  .landingScreen__section-joinus{
    margin:10px 30px 160px 30px;
  }
}
@media only screen and (max-width:680px) {
  .landingScreen__section-joinus {
    width: calc(100% - 60px);
    margin:10px 30px 100px 30px;
    overflow: hidden;
  }
  .landingScreen__section-joinusWrapper h2, .landingScreen__section-joinusWrapper p {
    width:100%;
    max-width:100%;
  }
}
@media only screen and (max-width:468px) {
  .landingScreen__section-joinus {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
  }
}
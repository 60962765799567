

.usbSeal {
  position: absolute;
  width: 100px;
  top: 310px;
  right: 6%;
  -webkit-animation:spin 8s linear infinite;
  -moz-animation:spin 8s linear infinite;
  animation:spin 8s linear infinite;
}
.usbSeal img {
  display: block;
  width: 100%;
  opacity: 0.2;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
@media only screen and (max-width: 680px) {
  .usbSeal {
    width: 80px;
    top: 210px;
    right: 30px;
  }
}
@media only screen and (max-width: 480px){
  .usbSeal {
    width: 60px;
  }
}





.SFAbanner {
  width: calc(100% - 120px);
  padding: 20px 60px;
  position: relative;
  margin-bottom: 60px;
}
.SFAbanner > div {
  background-color: #f2f6fb;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;
}
.SFAbanner > div > p {
  padding: 10px 30px 10px 30px;
  text-align:center;
}
.tagAlert {
  color: #1976d2;
  font-weight:bold;
  padding-right: 10px;
}
@media only screen and (max-width: 480px){
  .SFAbanner {
    width: calc(100% - 30px);
    padding: 10px 15px;
  }
}
.sfaAdmin {
  margin: 100px 60px;
}
.sfaAdmin h1 {
  text-align:center;
}



/* Table */
.sfaAdmin__dragScrollableTable {
  padding-top: 20px;
  border: 1px solid #ddd;
}
.sfaAdmin .row {
  display:flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 20px;
}
.sfaAdmin .row > * {
  padding-right: 7px;
}
.table {
  width: 100%;
  display:block;
  border-style: inset;
  border-collapse: collapse;
  border: 0;
}
.filterable-table-container td, .filterable-table-container th {
  padding: 0 10px;
  border-right: 1px solid #ededed;
}
.filterable-table-container tr {
  border-top: 1px solid #ddd;
}
.filterable-table-container th {
  min-width: 40px;
  text-align:left;
  line-height: 1.1;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f7f7f7;
}
.filterable-table-container th span {
  font-size: 0.8em;
  font-weight: 700;
}
.filterable-table-container td {
  border-top: 1px solid #ddd;
}
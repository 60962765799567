.qrLanding {
  padding-top: 100px;
}

.qrLanding__hero {
  padding: 0 60px;
  text-align:center;
}
.qrLanding__hero p {
  max-width: 780px;
  margin:0 auto;
  margin-bottom: -15px;
}
.qrLanding__hero img {
  display: block;
  width: 60%;
  margin: 0 auto;
}
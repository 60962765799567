.torsoView__markOneBiliary {
  top: 156px;
  left: 60px;
}
.torsoView__markTwoBiliary {
  top: 132px;
  left: 93px;
}
.torsoView__markThreeBiliary {
  top: 171px;
  left:95px;
}





@media only screen and (max-width: 680px) {
  .torsoView__markOneBiliary {
    top: 110px;
    left: 42px;
  }
  .torsoView__markTwoBiliary {
    top: 94px;
    left: 63px;
  }
  .torsoView__markThreeBiliary {
    top: 122px;
    left:69px;
  }
}
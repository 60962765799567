/* Login page */

.SfaLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding:30px;
}
.SfaContainer__conentWrapper {
  display: flex;
  background-color:#f2f6fb;
}

.SfaContainer__conentWrapper > * {
  width: 100%;
}
.SfaLogin__contentWrapper {
  min-width: 360px;
  padding: 30px 0;
  margin: 0 30px;
  text-align: center;
}
.SfaLogin__content-intro {
  background-color: #000;
  display:flex;
}
.SfaContainer__conentWrapper video{
  width: 100%;
}
.SfaLogin h1{
  font-size: 40px;
  margin-bottom:0!important;
}
.SfaLogin__description {
  padding: 30px 30px 60px;
  border-bottom: 1px solid #1976d2;
  margin-bottom: 60px;
}
.SfaLogin__toggleTabWrapper {
  display: flex;
  justify-content: center;
}

.SfaLogin_toggle {
  margin: 15px 5px;
  padding: 0 7px;
}
.SfaLogin_toggleSelected {
  color: #1976d2;
  border-bottom: 4px solid #1976d2;
}
.SfaLogin_toggleUnselected {
  color: #666;
}
.SfaLogin_toggleUnselected:hover {
  cursor: pointer;
}
.SfaLogin__userNameInfo {
  color: #666;
  padding-top: 10px;
}
@media only screen and ( max-width: 1050px){
  .SfaContainer__conentWrapper {
    flex-direction: column;
  }
  .SfaLogin {
    width: calc(100% - 60px);
    padding: 30px;
  }
  .SfaLogin__contentWrapper {
    margin: 0;
    padding: 20px 20px 10px 20px;
    text-align: center;
  }
}
@media only screen and ( max-width: 680px){
  .SfaLogin__contentWrapper {
    min-width: auto;
  }
}
@media only screen and ( max-width: 468px){
  .SfaLogin__content {
    display: flex;
    flex-direction: column;
  }
  .SfaLogin__content input[type="text"]{
    min-width: auto;
    margin: 10px 0;
  }
}


.SfaLogin__members {
  display: flex;
}
.SfaLogin__memberGroup {
  padding-bottom: 60px;
  width: 100%;
}
.SfaLogin__memberGroupTitle {
  padding-bottom:20px;
  color: #1976d2;
  text-align: center;
}
.SfaLogin__memberList {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.SfaLogin__member {
  /* width: calc(100% - 60px); */
  width: 100%;
  border: 1px solid #ddd;
  margin: 3px 15px;
  display: flex;
  padding: 10px 18px;
  align-items: center;
}
.SfaLogin__memberPhoto {
  height: 60px;
  width: 60px;
  min-width: 60px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 15px;
}
.SfaLogin__memberPhoto img {
  display: block;
  width: 100%;
}
@media only screen and ( max-width: 1130px){
  .SfaLogin__members {
    flex-wrap: wrap;
  }
  .SfaLogin__memberGroup {
    width: 50%;
  }
}
@media only screen and ( max-width: 840px){
  .SfaLogin__memberGroup {
    width: 100%;
  }
}




/* SfaAgreement */
.SfaAgreement input {
  margin-right: 10px;
  padding: 5px;
}
.SfaAgreement label {
  margin-right: 20px;
}
.SfaAgreement__consentWrapper {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.SfaAgreement__consentWrapper button {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
}
@media only screen and ( max-width: 468px){
  .sfaAgreementWrapper {
    max-height:450px;
  }
  .surveyQuestionInputWrapper input[type="radio" i] {
    min-width: 15px;
    min-height: 15px;
  }
}






/* SfaSurvey */
.SfaSurvey {
  text-align:center;
}
.SfaSurveyQuestions{
  max-width: 650px;
  margin: 0 auto;
  text-align: left;
}
.surveyQuestion {
  margin-bottom: 30px;
}
.surveyQuestion p {
  font-weight: 700;
  margin-bottom:10px;
}
.surveyQuestionInputWrapper * {
  padding: 2px 0;
}
.surveyQuestionInputWrapper label {
  margin: 0 5px!important;
}




/* Questions process */
.sfaQuestionProcessWrapper {
  height: fit-content;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
}
.sfaQuestionProcessText {
  width: min-content;
  padding: 0 20px;
  border-right: 1px solid #ddd;
  color: #1976d2;
}
.sfaQuestionProcessText p{
  white-space: nowrap;
  font-weight: 700;
}
.sfaQuestionProcessText .sfaQuestion__cheatsheetToggleBtn {
  text-decoration: underline;
}
.sfaQuestionProcessText .sfaQuestion__cheatsheetToggleBtn:hover {
  cursor: pointer;
}
.sfaQuestionProcessFrame {
  width: 100%;
  background-color: #f2f6fb;
  height:5px;
}
.sfaQuestionProcess {
  height: 5px;
  background-color: #1976d2;
}
/* Questions */
.sfaQuestionWrapper {
  display: flex;
}
.sfaQuestionWrapper > div {
  width: 100%;
}
/* Question img content */
.sfaQuestion__viewWrapper {
  position: relative;
}
.sfaQuestion__viewSticky {
  position: sticky;
  top: 100px;
}
.sfaQuestion__viewUS {
  position: relative;
}
.sfaQuestion__viewUS video{
  width:100%;
  min-width: 500px;
}
.sfaQuestion__viewTorso {
  position:absolute;
  top: 5px;
  left:5px;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
}
.sfaQuestion__viewTorso img {
  width: 100%;
  display: block;
}
.sfaQuestion__viewZoomToggle {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9;
}
.sfaQuestion__viewZoomToggle svg{
  width: 50px;
  height: 50px;
  fill: #fff;
}
.sfaQuestion__viewZoomToggle:hover {
  cursor: pointer;
}


/* Question text contents */
.sfaQuestion__contentWrapper {
  padding: 20px 0 20px 40px;
}
.sfaQuestion__contentHeader {
  margin-bottom: 10px;
}
.sfaQuestion__contentHeader-question {
  font-weight: 700;
}
.sfaQuestion__contentCheatsheetBtn {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.sfaQuestion__contentCheatsheetBtn:hover {
  cursor: pointer;
  background-color: #efefef;
}
.sfaQuestion__cheatsheetToggleBtnWithIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sfaCheatsheetBackdrop, .sfaUsZoomViewBackdrop{
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0 ,0, 0.4);
  width: 100%;
}
.sfaCheatsheetModalWrapper, .sfaUsZoomViewWrapper {
  z-index: 99999;
  position: absolute;
  width: calc(100% - 120px);
}
.sfaCheatsheetModalWrapper {
  pointer-events: none;
}
.sfaUsZoomViewWrapper {
  background-color: #000;
}
.sfaCheatsheetModal{
  background-color: #fff;
  max-width: 960px;
  margin: 0 auto;
  height: calc(100vh - 250px);
  padding: 60px 30px 30px 30px;
  position: relative;
  overflow: scroll;
  pointer-events: auto;
}
.sfaUsZoomView{
  background-color: #000;
  margin: 0 auto;
  height: calc(100vh - 200px);
  position: relative;
  overflow: hidden;
  pointer-events: auto;
  display: flex;
}
.sfaUsZoomView video {
  width: 100%;
  background-color: #000;
  max-height: 100%;
}
.sfaCheatsheetModal-close, .sfaUsZoomView-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.sfaUsZoomView-close p {
  color: #fff;
}
.sfaCheatsheetModal-close:hover, .sfaUsZoomView-close:hover {
  cursor: pointer;
}
/* .Cheatsheet__sectionWrapper {
  display: flex;
}
.Cheatsheet__sectionWrapper > div {
  width: 100%;
  padding:20px;
} */






.sfaQuestion__contentChoices {
  margin-bottom: 20px;
}
.sfaQuestion__contentChoices-choice input[type="checkbox"]{
  margin: 1em 0;
  position: absolute;
  visibility: hidden;
}
.sfaQuestion__contentChoices input[type="checkbox"]+label {
  align-items: center;
  border: 1px solid #ededed;
  display: flex;
  margin-bottom: 5px;
  padding: 10px;
  color: #666;
}
.sfaQuestion__contentChoices input[type="checkbox"]:checked+label {
  background-color: #f2f6fb;
  border-color: #1976d2;
  color: #000;
}
/* Mark answer after submit */
.sfaCorrectChoice input[type="checkbox"]:checked+label{
  background-color: #e1f0d1;
  border-color: #709e3f!important;
}
.sfaIncorrectChoice input[type="checkbox"]:checked+label{
  background-color: #f8e5e5;
  border-color: #cb4949!important;
}
/* Explanation */
.sfaAnswerExpWrapper {
  background-color: #f2f6fb;
  padding: 20px;
  margin-bottom: 20px;
}
.sfaAnswerExpWrapper > div {
  margin-bottom: 20px;
}
.sfaAnswerExp-answer p, .sfaAnswerExp-title{
  font-weight: 700;
}
.sfaQuestion__correctBadge {
  color:#fff;
  padding: 5px 10px;
  font-size: 0.8em;
  width: fit-content;
  border-radius: 100px;
  margin-bottom: 5px;
  display: block;
}
.sfaQuestion__correctBadge-correct {
  background-color: #709e3f;
} 
.sfaQuestion__correctBadge-incorrect {
  background-color: #cb4949;
}


@media only screen and ( max-width: 950px) {
  .sfaQuestionWrapper {
    flex-direction: column;
    align-items: center;
  }
  .sfaQuestionProcessWrapper {
    justify-content: center;
  }
  .sfaQuestion__viewWrapper {
    max-width: 640px;
  }
  .sfaQuestion__viewUS video {
    min-width: auto;
  }
  .sfaUsZoomViewWrapper {
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    position:fixed;
  }
  .sfaUsZoomView {
    height:100%;
  }
  .sfaUsZoomView-close {
    top: 80px;
    right: 60px;
  }
  .sfaQuestion__contentWrapper {
    padding: 20px 0;
    max-width: 640px;
  }
  .sfaQuestion__next {
    text-align: center;
  }
}
@media only screen and ( max-width: 680px) {
  .sfaUsZoomView-close {
    right: 40px;
  }
  .sfaCheatsheetModalWrapper {
    width:95vw;
    left: 2.5vw;
  }
  .sfaQuestion__viewTorso {
    width: 150px;
    height: 150px;
  }
}
@media only screen and ( max-width: 486px) {
  .sfaQuestionProcessWrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .sfaQuestionProcessText {
    margin-bottom: 10px;
  }
  .sfaCheatsheetModal {
    padding: 60px 15px 30px 15px;
  }
}




/* SFA DONE */
.sfaDone {
  text-align:center;
}
.sfaDone img {
  display: block;
  width: 50%;
  max-width: 300px;
  margin: 0 auto;
}








.signupScreen {
}
.signupScreen > div {
  max-width: 300px;
  margin: 0 auto;
}
.signupScreen a {
  margin-bottom: 20px;
  display: block;
  width: fit-content;
  margin: 15px auto;
}
.signupScreen__tab {
  width:50%;
}
.signupScreen__body {
  margin-bottom: 40px;
}
.signupScreen__tabContent p {
  display:block;
  margin-bottom: 20px;
  padding-top:20px;
  text-align: center;
}
.signupScreen__tabContent input {
  margin-bottom: 10px;
  padding: 16.5px 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width:calc(100% - 20px);
}
.signupScreen_forgotPW {
  font-size: 1rem!important;
  padding-top: 0px!important;
  padding-bottom:10px!important;
  text-align:right;
  color:#999!important;
}
.signupScreen__tabContent button {
  cursor: pointer;
  width:100%;
}
.signupScreen__titleSelect {
  width: 100%;
  padding-bottom: 10px!important;
  font-weight: 300!important;
}
.signupScreen__titleSelect .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height:1em!important;
}
@media only screen and (max-width:680px) {
  .signupScreen {
    margin: 30px auto;
  }
}
@media only screen and (max-width:468px) {
  .signupScreen {
    padding:30px 15px;
  }
  .signupScreen a {
    text-align:center;
  }
}
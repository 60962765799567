.marquee__screenCarousel-1 marquee div:first-child{
  margin-left: -99vw;
}
.marquee__screenCarousel-1 marquee div, .marquee__screenCarousel-2 marquee div {
  margin: 5px 10px;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 200px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.marquee__screenCarousel-2 marquee div:last-child{
  margin-right: -99vw;
}
@media only screen and (max-width: 680px) {
  .marquee__screenCarousel-2 {
    margin-top: -7px;
  }
  .marquee__screenCarousel-1 marquee div, .marquee__screenCarousel-2 marquee div {
    width: 140px;
    height: 80px;
  }
}
@media only screen and (max-width: 480px){
  .marquee__screenCarousel-2 {
    margin-top: -7px;
  }
  .marquee__screenCarousel-1 marquee div, .marquee__screenCarousel-2 marquee div {
    width: 100px;
    height: 60px;
    border-radius: 5px;
    margin: 3px 5px;
  }
}
.wmaLandingScreen{
  width: calc(100% - 120px);
  margin:0 auto;
  padding: 80px 60px 180px;
  overflow:hidden;
}


.wmaLanding__revisitToggle {
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
.wmaLanding__revisitToggleBg {
  background-color:#fff;
  border-radius:400px;
  width: 45px;
  border: 1px solid #ddd;
  display:flex;
}
.wmaLanding__revisitToggleButton {
  width: 24px;
  height: 24px;
  background-color: #1976d2;
  border-radius: 400px;
  border: 3px solid #fff;
}


.wmaLanding__intro {
  text-align:center;
  position:relative;
}
.wmaLanding__intro::after {
  background: linear-gradient(45deg,rgba(199,137,241,.11) 30%,rgba(62,56,231,.118) 90%);
  border-radius: 50%;
  content: "";
  filter: blur(40px);
  height: 75%;
  left: 50%;
  position: absolute;
  right: auto;
  top: -400px;
  transform: rotate(60deg);
  width: 200px;
  z-index: -1;
}

.wmaLanding__processWrapper {
  box-shadow: #ddd 0 0 0 1px inset;
  border-radius: 4px;
  display: flex;
}
.wmaLanding__processLeftPanel {
  width: 200px;
  padding: 50px;
  text-align:left;
  border-right: 1px solid #ddd;
}
.wmaLanding__processLeftPanel p{
  line-height: 2.5;
  display:flex;
  align-items: center;
}
.wmaLanding__processCurrent {
  color: #1976d2;
}
.wmaLanding__processPast {
  color:#999;
}
.wmaLanding__processLeftPanel svg {
  margin-right:10px;
}
.wmaLanding__revisitToggle p {
  line-height: 1.4;
}
.wmaLanding__processRightPanel {
  padding: 50px;
  width:calc(100% - 250px);
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
@media only screen and ( max-width: 1300px) {
  .wmaLanding__processWrapper {
    flex-direction: column;
    margin-top: 30px;
  }
  .wmaLanding__processLeftPanel {
    width: auto;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display:flex;
    justify-content: space-around;
    padding:20px;
  }
  .wmaLanding__processLeftPanel p {
    display: inline-flex;
  }
  .wmaLanding__processRightPanel {
    width: auto;
    height: calc(100vh - 350px);
  }
}


.wmaLanding__intro-video {
  /* display: flex; */
  align-items: center;
}
.wmaLanding__intro-video > * {
  /* width: 100%; */
}
.wmaLanding__intro-video p {
  padding: 30px;
  text-align:left;
}
.wmaLanding__input {
  padding-top: 30px;
  margin: 0 auto;
  text-align: center;
}
.wmaLanding__input input {
  padding:16.5px 10px;
  min-width: 250px;
  margin: 10px;
}
.wmaLanding__input-help {
  padding: 0 20px;
}
@media only screen and ( max-width: 1350px) {
  .wmaLanding__intro-video{
    flex-direction: column;
  }
  .wmaLanding__intro-video iframe {
    max-width: 640px;
  }
}
@media only screen and (max-width: 700px) {
  .wmaLandingScreen {
    width: calc(100% - 40px);
    padding: 80px 20px 180px;
  }
  .wmaLanding__intro-video {
    max-width: calc(100vw - 40px);
    margin: 0 auto;
  }
  .wmaLanding__input form > * {
    display:block;
    text-align:center;
    margin: 10px auto;
  }
}


.wmaLanding__progressBg {
  width: calc(100% - 40px);
  padding: 30px 20px;
}
.wmaLanding__prograssBarWrapper {
  width:100%;
  padding-top: 2px;
  display: flex;
  flex-wrap: wrap;
}
.wmaLanding__progressBar {
  display: block;
  text-align:center;
  font-size: 12px;
  line-height: 24px;
  width: 24px;
  border-radius: 500px;
  margin-right: 3px;
  margin-bottom: 5px;
}








.wmaQuestions__content {
  display: flex;
  width: 100%;
}
.wmaQuestions__content > * {
  width: 50%;
}

.wmaQuestions__view {
  background-color:#000;
  position: relative;
  min-height: 400px;
}
.wmaQuestions__usview {
  position: absolute;
  width:calc(100% - 20px);
  height: calc(100% - 20px);
  right: 10px;
  top: 10px;
  text-align:center;
}
.wmaQuestions__usview video {
  max-height:100%;
  max-width: 100%;
}
.wmaQuestions__torso {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 9;
  width: 100px;
}
.wmaQuestions__zoomClose {
  position:absolute;
  color:#fff;
  right: 40px;
  top: 30px;
  background-color:#000;
  cursor: pointer;
  text-decoration: underline;
  z-index: 999999;
}
.wmaQuestions__zoomedUsView video{
  display: block;
  width:100%;
}
@media only screen and (max-width: 700px){
  .wmaQuestions__content {
    flex-direction: column;
  }
  .wmaQuestions__content > * {
    width: 100%;
  }
}



.wmaLanding__zoomPopup {
  width: 100vw;
  height: 100vw;
  background-color: #000;
}
.wmaQuestions__zoomIcon {
  color:#fff;
  font-size: 1.8em!important;
  position:absolute;
  bottom: 20px;
  left: 110px;
}



.wmaQuestions__questionWrapper > * {
  padding: 0 30px;
}
.wmaQuestions__question {
  padding-bottom: 30px;
}
.wmaInfoImg__wrapper {
  width:90%;
  margin:0 auto;
  z-index:9999;
  position:fixed;
  min-height: 100vh;
  top:0;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color:#0000007d;
}
.wmaInfoImg__content {
  max-width: 80vw;
  background-color: #fff;
  padding-top:30px;
  border-radius: 4px;
  position:relative;
  overflow:hidden;
}
.wmaInfoImg__content h2, .wmaInfoImg__content p {
  padding: 0 20px;
}
.wmaInfoImg__content p {
  padding-bottom: 10px;
}
.wmaInfoImg__content img {
  display: block;
  width:auto;
  max-width: 80vw;
}
.wmaInfoImg__content-close {
  position: absolute;
  top:10px;
  right:20px;
  display: flex;
  justify-content: center;
  color: #1976d2;
  font-weight:bold;
  cursor: pointer;
}
.wmaQuestions__correctWrongBadge {
  color:#fff;
  font-size: 0.7em;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 50px;
}
.wmaQuestions__answerViewToggleWrapper {
  display:flex;
  align-items: center;
  padding-top: 10px;
  width: fit-content;
}
.wmaQuestions__answerViewToggleWrapper > span {
  color: #999;
}
.wmaQuestions__answerViewToggleBg {
  background-color: #ededed;
  border: 1px solid #999;
  padding: 2px;
  margin: 0 5px;
  border-radius: 100px;
  width: 35px;
  display: flex;
}
.wmaQuestions__answerViewToggle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.wmaQuestions__optionWrapper {
  font-size: .9em;
  color: #666;
}
.wmaQuestions__option {
  position:relative;
  max-width: 600px;
}
.wmaQuestions__option input[type="checkbox"] {
  height:100%;
  width: 100%;
  position:absolute;
  opacity: 0;
}
.wmaQuestions__option input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ededed;
  margin-bottom: 5px;
}
.wmaQuestions__option input[type="checkbox"] + label:hover {
  cursor: pointer;
}
.wmaQuestions__option input[type="checkbox"]:checked + label {
  background-color: #f7f7f7;
  border-color: #1976d2;
  color: #000;
}



.wmaCorrect label{
  background-color: #e1f0d1!important;
  border: 1px solid #709e3f!important;
  color: #000!important;
}
.wmaCorrect label::before {
  content: "Answer";
  color: #709e3f;
  font-size: 0.8em;
  padding: 0 10px;
  position:absolute;
  right: 10px;
  border-radius: 50px;
  border: 1px solid #709e3f;
}
.wmaCorrect label::after {
  content: "Correct";
  color: #709e3f;
  font-size: 0.8em;
  padding-left: 5px;
}
.wmaWrong label {
  background-color:#f8e5e5!important;
  border:1px solid #cb4949!important;
  color:#000!important;
}
.wmaAnswerMark label::before {
  content: "Answer";
  color: #cb4949;
  font-size: 0.8em;
  padding: 0 10px;
  position:absolute;
  right: 10px;
  border-radius: 50px;
  border: 1px solid #cb4949;
}
.wmaWrongMark label::after{
  content: "Wrong";
  color: #cb4949;
  font-size: 0.8em;
  padding-left: 5px;
}





.wmaLanding__complete-contentWrapper {
  padding: 60px 20px;
  text-align:center;
}







.wmaLanding__nextButton {
  margin-top:50px;
  text-align:center;
}
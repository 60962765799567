.nav {
  position: fixed;
  top: 0;
  width:100%;
  height: 65px;
  z-index: 9999999999999999;
  /* Animation */
  transition-timing-function: ease-in;
  transition: all 0.5;
}
.nav__bg{
  width: 100%;
  height: calc(100vh - 60px);
  position: absolute;
  top: 60px;
}
.nav__contents {
  background-color: #fff;
  box-shadow: 0.5px 0.5px 0.5px rgb(0, 0, 0, .4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 60px;
}


 

.nav__contentsNav {
  display: flex;
}
.nav__contentsNav > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  color:#000;
  text-transform: capitalize;
}
.nav__contentsNav * {
  line-height:40px;
}
.nav__contentsDir {
  display: flex;
  color: #fff;
  padding-right: 40px;
}
.nav__contentsDir > *{
  padding: 10px 15px;
  cursor: pointer;
}
.nav__currentDesktop {
  -webkit-box-shadow:inset 0px -5px 0 #1976d2;
  -moz-box-shadow:inset 0px -5px 0 #1976d2;
  box-shadow:inset 0px -5px 0 #1976d2;
}
.nav__currentMobile {
  border-left: 10px solid #1976d2;
}
.nav__contentsDir > *:hover {
  color: #1976d2;
}
@media only screen and (max-width: 1120px) {
  .nav__contentsDir {
    display: none!important;
  }
}




.nav__contentsDropdown {
  position: absolute;
  top: 60px;
  right: 266px;
  background-color:#f7f7f7;
  /* box-shadow: 2px 4px 12px rgb(0 0 0 / 8%); */
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}
.nav__contentsDropdownLoggedIn {
  position: absolute;
  top: 60px;
  right: 390px;
  background-color:#f7f7f7;
  /* box-shadow: 2px 4px 12px rgb(0 0 0 / 8%); */
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}
.nav__contentsDropdown p, .nav__contentsDropdownLoggedIn p {
  padding: 15px 60px;
}
.nav__contentsDropdown p:hover, .nav__contentsDropdownLoggedIn p:hover {
  background-color: #ececec;
}
@media only screen and (max-width: 1120px) {
  .nav__contentsDropdown, .nav__contentsDropdownLoggedIn {
    display: none;
  }
}





.nav__logoWrapper {
  display: flex;
  align-items: flex-start;
}
.nav__logoWrapper p {
  font-size: 0.6em;
  padding-left:5px;
  color:#999;
}
.nav__logo {
  left: 0;
  height: 25px;
  cursor: pointer;
}
@media only screen and (max-width: 680px) {
  .nav__logo {
    height: 20px;
  }
  .nav__contents {
    padding:0 30px;
  }
}
@media only screen and (max-width: 468px){
  .nav__contents {
    padding: 0 15px;
  }
  .ub_textWhite {
    font-size: 16px;
  }
}





/* Nav dropdown */
.nav__toggleDropdown {
  cursor: pointer;
  padding: 10px 0;
}
.nav__burger {
  display: flex;
  align-items: center;
  padding:10px 0 10px 20px;
}
.nav__toggleArrow {
  display: flex;
  transition-duration: 0.2s;
}
.nav__toggleArrow.arrowReverse {
  transform: rotate(-90deg);
  transition-duration: 0.2s;
}
.nav__dropdown {
  padding-top: 80px;
}
.nav__dropdown ul {
  padding-left: 0;
}
.nav__dropdown li {
  list-style: none;
  cursor: pointer;
  padding: 15px 60px 15px 30px;
}
.nav__dropdown li:hover {
  background-color: #f2f6fb;
}
.nav__dropdown hr {
  margin: 15px 0;
}

.torsoView__markOnePelvic {
  top: 235px;
  left: 105px;
}
.torsoView__markTwoPelvic {
  top: 253px;
  left: 141px;
}
.torsoView__markThreePelvic {
  top: 253px;
  left:72px;
}
.torsoView__markFourPelvic {
  top: 264px;
  left:105px;
}


@media only screen and (max-width: 680px) {
  .torsoView__markOnePelvic {
    top: 164px;
    left: 75px;
  }
  .torsoView__markTwoPelvic {
    top: 184px;
    left: 100px;
  }
  .torsoView__markThreePelvic {
    top: 174px;
    left: 49px;
  }
  .torsoView__markFourPelvic {
    top: 193px;
    left:75px;
  }
}
.contactScreen {
  padding-top:100px;
}
.contactScreen__contact {
  padding: 60px;
  text-align: center;
}
@media only screen and (max-width:680px) {
  .contactScreen__contact {
    padding: 60px 30px;
  }
}
@media only screen and (max-width:468px) {
  .contactScreen__contact {
    padding: 60px 15px;
  }
}
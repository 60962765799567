.row {
  padding: 0;
}
.row > p {
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0 60px;
  max-width: 1440px;
}
.row__welcome {
  margin-bottom: 100px;
  text-align: left;
  background-image: url('https://ultrasoundbox.s3.amazonaws.com/img/hero-asset_pp.gif');
  background-size: 120vw;
  background-repeat: no-repeat;
  background-position: top left;
  color: #fff;
  padding: 160px 60px 100px 60px;
}


.row__categories {
  margin: 0 auto;
  border-radius: 4px;
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  padding: 0 60px;
}

.row__categoryRow {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin: 10px;
  width: calc(25% - 62px);
  padding:15px 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}
.row__categoryTitle {
  text-transform: capitalize;
  word-break: break-all;
  display: flex;
}
.row__categoryTitle img{
  display: none;
}
.row__categoryRow:hover .row__categoryTitle img {
  width: 30px;
  margin-left: 20px;
  display: inline-block;
}
.row__categoryRow:hover::after {
  content: "Select";
  text-transform: capitalize;
  display: block;
  position: absolute;
  background-color: #ededed;
  color: #333;
  top: -10px;
  right: 10px;
  font-size:0.6em;
  padding: 3px 10px;
  border-radius: 100px;
}
.row__categoryButton {
  margin-right:10px;
}
@media only screen and (max-width: 1500px) {
  .row__categoryRow{
    width: calc(33% - 62px);
  }
}
@media only screen and (max-width: 1150px) {
  .row__categories {
    flex-wrap: wrap;
  }
  .row__categoryRow{
    width: calc(50% - 62px);
  }
}
@media only screen and (max-width: 800px) {
  .row__categories {
    flex-wrap: wrap;
  }
  .row__categoryRow {
    padding: 20px;
    margin: 5px 0;
    width: 100%;
  }
  .row__categoryRow:hover::after {
    display: none;
  }
  .categoryChosen::after {
    display: block!important;
    right: 20px;
    left: auto!important;
    top: auto!important;
  }
  .row__categoryInfo {
    flex-direction: row!important;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 5px;
  }
  .row__categoryProgress {
    margin-bottom: 0!important;
  }
  
  .row .css-1d6wzja-MuiButton-startIcon {
    display: none;
  }
}
@media only screen and (max-width: 680px) {
  .row > p {
    text-align:center;
  }
}
@media only screen and (max-width: 468px){
  .row > p {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .row__categories {
    padding: 0 15px;
  }
}




.row__categoryInfo {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.row__categoryProgress {
  margin-left:10px;
  color: #999;
}
.categoryChosen {
  border: 1px solid #1976d2;
  /* background-image: url("https://ultrasoundbox.s3.amazonaws.com/img/cornershine.png");
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat; */
}
.categoryChosen::after {
  content: "selected"!important;
  text-transform: capitalize;
  display: block;
  position: absolute;
  background-color: #1976d2!important;
  color: #fff!important;
  top: -10px;
  right: 10px;
  font-size: 0.6em;
  padding: 3px 10px;
  border-radius: 100px;
}

.row__blockWrapper {
  text-align:center;
  margin-bottom: 160px;
}
.row__blockNotSelected {
  color: #999;
  display: flex;
  padding: 0 60px;
  max-width: 1440px;
  margin: 0 auto;
}
@media only screen and (max-width:680px) {
  .row > p {
    padding: 0 30px;
  }
  .row__blockNotSelected {
    justify-content: center;
  }
}
@media only screen and (max-width: 468px) {
  .row__blockNotSelected {
    padding: 0 20px;
  }
}






/* Progressbar */
.row__progressBarWrap {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom:0;
  left: 0;
  display: flex;
  align-items: center;
}
.row__progressBarFrame {
  width:100%;
  border-top: 1px solid #ededed;
  height: 100%;
  overflow: hidden;
}
.row__progressBar {
  height: 100%;
  background-color: #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.categoryChosen .row__progressBar {
  background-color:#1976d2;
}




/* WIP categories placeholder */
.row__wipBox p {
  padding: 0 60px;
}
.row__wipBox img{
  display: block;
  max-width: 250px;
  margin: 0 auto;
  padding-top: 15px;
}
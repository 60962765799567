.torsoView__markOneOcular {
  top: 119px;
  left: 102px;
}
.torsoView__markTwoOcular {
  top: 174px;
  left: 72px;
}
.torsoView__markThreeOcular {
  top: 113px;
  left:52px;
}


@media only screen and (max-width: 680px) {
  .torsoView__markOneOcular {
    top: 85px;
    left: 72px;
  }
  .torsoView__markTwoOcular {
    top: 121px;
    left: 49px;
  }
  .torsoView__markThreeOcular {
    top: 79px;
    left:36px;
  }
}
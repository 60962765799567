.torsoView__markOneTrauma {
  top: 155px;
  left: 28px;
}
.torsoView__markTwoTrauma {
  top: 155px;
  left: 178px;
}
.torsoView__markThreeTrauma {
  top: 285px;
  left: 101px;
}
.torsoView__markFourTrauma {
  top: 150px;
  left: 103px
}
.torsoView__markFiveTrauma {
  top: 74px;
  left: 55px;
}
.torsoView__markSixTrauma {
  top: 74px;
  left: 152px;
}





@media only screen and (max-width: 680px) {
  .torsoView__markOneTrauma {
    top: 113px;
    left: 19px;
  }
  .torsoView__markTwoTrauma {
    top: 116px;
    left: 124px;
  }
  .torsoView__markThreeTrauma {
    top: 200px;
    left:72px;
  }
  .torsoView__markFourTrauma {
    top: 105px;
    left: 72px
  }
  .torsoView__markFiveTrauma {
    top: 51px;
    left: 38px;
  }
  .torsoView__markSixTrauma {
    top: 51px;
    left: 108px;
  }
}
.torsoView__markOneRenal {
  top: 155px;
  left: 31px;
}
.torsoView__markTwoRenal {
  top: 155px;
  left: 176px;
}
.torsoView__markThreeRenal {
  top: 283px;
  left:102px;
}


@media only screen and (max-width: 680px) {
  .torsoView__markOneRenal {
    top: 111px;
    left: 22px;
  }
  .torsoView__markTwoRenal {
    top: 111px;
    left: 126px;
  }
  .torsoView__markThreeRenal {
    top: 202px;
    left:73px;
  }
}
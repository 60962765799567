.contributionCategory {
  background-color:#fff;
  padding: 0 60px 60px 60px;
  position: relative;
}


.contributionCategory .css-qivjh0-MuiStepLabel-label, .css-qivjh0-MuiStepLabel-label.Mui-active, .css-ahj2mt-MuiTypography-root {
  font-family: inherit!important;
  font-size: inherit!important;
}
.contributionHelpPopupTitle {
  font-weight: bold;
  display: block;
  padding-top: 10px;
}



.contributionCategory__2colSection {
  display: flex;
}
.contributionCategory__2colSection > .contributionCategory__section {
  width: calc(100% - 20px);
}
.contributionCategory__section {
  margin-bottom: 30px;
}
.contributionCategory__section h3 {
  display: block;
  width: calc(100% - 14px);
  font-size: 0.9em;
}
.contributionCategory__helpLink {
  
  display: block;
  margin-bottom: 30px;
}
@media only screen and (max-width: 800px) {
  .contributionCategory__2colSection {
    flex-direction: column;
  }
  .contributionCategory__2colSection > .contributionCategory__section {
    width: 100%;
  }
}
@media only screen and (max-width:640px) {
  .contributionCategory {
    padding-left: 30px;
    padding-right: 30px;
  }
}



/* Difficulty level */
.contributionCategory__difficultyWrapper {
  padding-right: 5%;
  margin-right: calc(5% - 1px);
  border-right: 1px solid #ddd;
}
.contributionCategory__difficulty > div {
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .contributionCategory__difficultyWrapper {
    border: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

/* Tags */
.contributionCategory__tags p{
  
  color: #999;
}
.ReactTags__tag {
  background-color: #000;
  color:#fff;
  
  padding: 3px 5px 3px 10px;
  border-radius: 100px;
  margin-right:5px;
}
.ReactTags__tagInputField {
  padding: 16.5px 14px;
  margin-top:10px;
  font-size:1rem;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  width: calc(100% - 30px);
}
.ReactTags__tag button {
  border: 0;
  background:none;
  font-size: 1.2em;
  color:#fff;
}

/* Credit section */
.contributionCategory__creditWrapper {
  width: 45%;
  padding-right:5%;
  margin-right: calc(5% - 1px);
  border-right:1px solid #ddd;
}
.contributionCategory__credit {
  display: flex;
  flex-wrap: wrap;
}
.contributionCategory__credit .MuiTextField-root {
  margin: 5px;
  width: calc(33% - 10px);
}
.contributionCategory__addAuthors {
  margin: 30px 0;
}
.contributionCategory__creditExtraTwo {
  margin-top:30px;
}
.contributionCategory__creditExtraOne > p, .contributionCategory__creditExtraTwo > p {
  width:100%;
  
}

@media only screen and (max-width: 1440px) {
  .contributionCategory__creditWrapper {
    flex-direction: column;
  }
  .contributionCategory__creditWrapper > div {
    width: 100%;
    border: 0;
    margin-bottom: 20px;
  }
  .contributionCategory__creditWrapper > div:first-child {
    margin-right:0;
    border:0;
    padding-right:0;
  }
}
@media only screen and (max-width: 800px) {
  .contributionCategory__2colSection .contributionCategory__creditWrapper {
    padding-right: 0;
    margin-right: 0;
    border :0;
  }
}
@media only screen and (max-width:680px) {
  
  .contributionCategory {
    padding-left: 15px;
    padding-right: 15px;
  }
  .contributionCategory__creditWrapper .MuiTextField-root {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 480px) {
  .contributionCategory__creditWrapper .MuiTextField-root {
    width: calc(100% - 10px);
  }
}


/* Props */
.contributionCategory__props {
  width: 40%;
}
.contributionCategory__props > div {
  width: 100%;
}
.contributionCategory__props .contributionCategory__creditWrapper .MuiTextField-root {
  width: calc(100% - 10px)!important;
}


/* Image upload */
.contributionCategory__imgUploadSection {
  display: flex;
  position: relative;
}
.contributionCategory__section > p {
  margin-bottom:20px;
}
.contributionCategory__torso {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  max-width: 200px;
}
.contributionCategory__torsoToggleMobile {
  position: absolute;
  
  background-color: #1976d2;
  line-height:1;
  color:#fff;
  width: 4.5rem;
  vertical-align: middle;
  text-align:center;
  padding: 1.3em 0 1.7em 0;
  border-radius: 100px;
  display: none;
}
.contributionCategory__torso img{
  display: block;
  width: 100%;
}
.contributionCategory__imgUploadWrapper {
  display: flex;
  width: 100%;
}
.contributionCategory__imgUploadWrapper > div {
  width: 100%;
}
.contributionCategory__imageUpload {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 10px 20px;
  width: calc(100% - 60px);
}
.contributionCategory__imageUpload span {
  
  color:#999;
  margin-top: -3px;
  display: block;
}
@media only screen and (max-width:1300px) {
  .contributionCategory__imgUploadWrapper {
    flex-direction: column;
  }
}
@media only screen and (max-width:640px) {
  .contributionCategory__torso {
    width: calc(4.5em + 20px);
    position: relative;
  }
  .contributionCategory__torso img{
    display: none;
  }
  .contributionCategory__torsoToggleMobile {
    display: block;
    position: sticky;
    top: 80px;
  }
}
@media only screen and (max-width: 480px) {
  .contributionCategory__imgUploadSection {
    flex-direction: column;
  }
  .contributionCategory__torsoToggleMobile {
    display: none;
  }
  .contributionCategory__torso {
    height: fit-content;
    width: 90%!important;
    margin: 0 auto;
  }
  .contributionCategory__torso img {
    display: block!important;
    height: auto;
    width: 90%;
  }
  .contributionCategory__imgUploadWrapper {
    width: 100%!important;
  }
}


/* Interpretation */
.contributionCategory__sectionInterpretationGuide {
  padding-top: 20px;
  
  padding-left:15px;
  color: #999;
}
.contributionCategory__sectionInterpretation > div {
  width: calc(100% - 10px);
  margin: 5px;
}
.contributionCategory__sectionInterpretationSample {
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 20px;
  width: calc(100% - 40px)!important;
}
.contributionCategory__sectionInterpretationSample span{
  display: block;
  background-color: #999;
  color:#fff;
  
  width:fit-content;
  padding: 3px 5px;
}
.contributionCategory__sectionInterpretationSample p {
  margin-bottom: 15px;
  
}
.contributionCategory__toggledTorso {
  position: fixed;
  z-index: 33;
  height: 100vh;
  width: calc(100vw - 30px);
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


/* Question and Answer */
.contributionCategory__QAGuide {
  
  color: #999;
}
.contributionCategory__QAWrapper {
  display: flex;
  padding-bottom:20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}
.contributionCategory__QAWrapper:last-child {
  border: 0;
  padding-bottom: 0;
}
.contributionCategory__QAWrapper > div {
  margin: 5px 10px;
  padding: 5px 0;
  width: calc(100% - 20px);
  overflow: hidden;
}
.contributionCategory__A > * {
  width: 100%;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  white-space: break-spaces!important;
}
.contributionCategory__section > div {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .contributionCategory__QAWrapper {
    flex-direction: column;
  }
}


/* Sender information */
.contributionCategory__senderInfoWrapper > p {
  
  
}
.contributionCategory__senderInfo > div {
  margin: 5px;
  width: calc(50% - 10px);
}
@media only screen and (max-width: 480px){
  .contributionCategory__senderInfo > *{
    width: calc(100% - 10px)!important;
  }
}

/* Submit button */
.contributionCategory .ub_solidPurple {
  margin-right: 30px;
}
.contributionCategory__submitButton {
  /* text-align:center; */
  width: fit-content;
  display: inline-block;
}
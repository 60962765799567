.Cheatsheet__sectionHeadline {
  border-top: 1px solid #ddd;
  background-color: #ededed;
  padding: 5px 20px;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align:center;
}

.Cheatsheet__sectionWrapper {
  display: flex;
  margin-bottom: 20px;
}
.Cheatsheet__sectionWrapper:first-child {
  padding-top: 60px;
}
.Cheatsheet__sectionWrapper > div {
  width: 100%;
}
.Cheatsheet__sectionWrapper video, .Cheatsheet__sectionWrapper img {
  width:100%;
  height:auto;
  min-width: 400px;
  display: block;
}
.Cheatsheet__sectionWrapper p {
  padding-left: 20px;
}
@media only screen and ( max-width: 840px){
  .Cheatsheet__sectionWrapper {
    display: flex;
    flex-direction: column;
  }
  .Cheatsheet__sectionWrapper p {
    padding-left: 0;
    padding-top: 5px;
    padding-bottom:10px;
  }
}
@media only screen and ( max-width: 680px ) {
  .Cheatsheet__sectionWrapper video, .Cheatsheet__sectionWrapper img {
    min-width: auto;
  }
}
.dataScreen {
  padding-top: 160px;
}
.dataScreen__pageTitle {
    text-align: center;
    padding-top: 40px;
}






.dataScreen__overallData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dataScreen__overallData > * {
  text-transform: capitalize;
}
.dataScreen__overallDataDonut {
  width: calc(40% - 80px);
  min-width: 240px;
  text-align:center;
  position: sticky;
  top: 0;
  display: block;
  margin: 0 auto;
  padding-bottom: 40px;
  margin: 0 auto;
}
.dataScreen__overallDataDonut > svg{
  padding: 20px;
}
.dataScreen__overallDataDonut > svg:first-child {
  padding-top:100px;
}
.dataScreen__overallDataContent {
  padding: 40px;
  width: calc(60% - 80px);
}
.dataScreen__overallDataContent span{
  padding-left: 10px;
  color: #999;
}
.dataScreen__eachCategoryData {
  padding: 40px;
  border: 1px solid #ddd;
  margin-bottom:10px;
}
.dataScreen__progressBarWrapper {
  width: 100%;
  height: 7px;
  background-color:#f7f7f7;
  position: relative;
}
.dataScreen__progressBar {
  height: 7px;
  background-color: #1976d2;
}
@media only screen and (max-width: 680px) {
  .dataScreen__overallData {
    flex-direction: column;
    align-items: center;
  }
  .dataScreen__overallData > * {
    width:calc(100% - 80px);
  }
  .dataScreen__overallDataDonut {
    display: inline-block;
    width:100%;
    padding-bottom: 0;
  }
}
.blockScreen {
  margin-top: 160px;
}
.blockScreen__blockTop{
  height: 65px;
  background-color:#000;
  margin-bottom: 100px;
}
.blockScreen__floatWrapper {
  position: sticky!important;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom:30px!important;
  margin-top: -170px!important;
  width: fit-content;
  float:right;
}
.blockScreen__float {
  margin-bottom: 10px!important;
  margin-right: 60px!important;
}
.blockScreen__floatWrapper .css-a8igs1-MuiButtonBase-root-MuiFab-root {
  background-color:#1976d2!important;
  color:#fff!important;
}
.blockScreen__floatWrapper .css-a8igs1-MuiButtonBase-root-MuiFab-root.Mui-disabled {
  background-color: #ddd!important;
}
@media only screen and (max-width:1240px) {
  .blockScreen {
    width:100%;
  }
}
@media only screen and (max-width: 680px) {
  .blockScreen__float {
    margin-right:15px!important;
  }
}
@media only screen and (max-width: 480px) {
  .blockScreen__floatWrapper {
    display:none;
  }
}



.blockScreen__floatViewWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
.blockScreen__floatViewBackdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:9;
  pointer-events: none;
}
.blockScreen__floatViewCloseWrapper {
  width: 100vw;
  max-width:1200px;
  margin: 0 auto;
  position: fixed;
  top:0;
  z-index: 999;
}
.blockScreen__floatViewClose {
  position: absolute;
  top: 80px;
  right: 30px;
  color:#fff;
  /* z-index: 9999; */
  text-decoration: underline;
  cursor: pointer;
}




/* Props style */
.blockScreen__prop {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.blockScreen__propBreadCrumb {
  color: #666;
  display: block;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.blockScreen__propBreadCrumb span {
  font-size: 1rem!important;
}
.blockScreen__qTags span {
  
  color: #999;
  padding-right: 10px;
}
.blockScreen__qTags p{
  
  display: inline-block;
  padding: 3px 10px;
  background-color:#fff;
  border: 1px solid #000;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius:100px;
  cursor:pointer;
}
.blockScreen__qTags p:hover {
  background-color:#f2f2f2;
}
.blockScreen__propQuestion {
  font-size: 1.5rem;
}
.blockScreen__credit {
  margin-top: 20px;
  
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 5px;
}
.blockScreen__creditAuthor {
  color: #999;
  background-color: #f7f7f7;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}
.blockScreen__creditAuthor:hover {
  background-color:#f2f2f2;
}
.blockScreen__creditAuthor span {
  color: #000;
  padding-left: 5px;
}
.blockScreen__authorPopup{
  position: relative;
  display: flex;
  justify-content: center;
}
.blockScreen__authorInfoWrapper {
  display: flex;
  max-width: 1200px;
  position: absolute;
  margin: 0 auto;
  width:100%;
}
@media only screen and (max-width: 640px ){
  .blockScreen__propQuestion {
    font-size: 1.2em;
  }
}






/* Question style */
.blockScreen__questionList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: 1200px;
}
.blockScreen__readingQ {
  width: calc(50% - 80px);
  padding: 40px;
  display: inline-block;
  vertical-align: top;
  box-shadow: 0 0 1px rgb(0 0 0 / 0.4);
}
.blockScreen__generalQ {
  width: calc(100% - 80px);
  padding: 40px;
  border-bottom: 1px solid #ddd;
}
.blockScreen__readingQ p, .blockScreen__generalQ p{
margin-bottom: 20px;
}
#blockScreen_Q:last-of-type {
  margin-bottom: 60px;
}
@media only screen and (max-width:1240px) {
  .blockScreen__score {
    margin: 0 30px 40px 30px!important;
  }
  .blockScreen__prop {
    padding: 0 30px;
  }
  .blockScreen__questionList {
  padding: 0 30px;
  }  
  .blockScreen__readingQ, .blockScreen__generalQ {
    width: calc(100%);
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-top: -1px;
    padding: 40px 10px;
  }
}


 /* Options style */
.blockScreen__optionWrapper {
  font-size: .9em;
  color: #666;
}
.blockScreen__optionWrapper input[type="radio"] {
  visibility: hidden;
  margin: 1em 0;
  position: absolute;
}

.blockScreen__optionWrapper input[type="radio"] + label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ededed;
  margin-bottom: 5px;
}

.blockScreen__optionWrapper input[type="radio"] + label:hover {
  cursor: pointer;
}
.blockScreen__optionWrapper input[type="radio"]:checked + label {
  background-color: #f2f6fb;
  border-color: #1976d2;
  color: #000;
}



/* Button */
.blockScreen__submitWrapper {
  display: block;
  width:100%;
}
.blockScreen__submit{
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}
.blockScreen__goHome {
  margin-top: 30px;
  line-height:3em;
}
@media only screen and (max-width: 480px) {
  .blockScreen__submitWrapper > * {
    display:block;
  }
}





/*  */
/*  */
/*  */
/* AFTER SUBMIT */
.radio-readOnly {
  pointer-events: none;
}
.blockScreen__score {
  background: linear-gradient(45deg, #c789f1 30%, #1976d2 90%);
  text-align:center;
  font-size: 1.3rem;
  color: #fff;
  padding: 10px 0;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 5px;
  max-width: 1200px;
}
.blockScreen__score span {
  font-weight: bold;
}
.blockScreen__score span.ub_textWhite {
  margin-left: 20px;
}

.correct {
  color: #666;
}
.correct input[type=radio]:checked + label {
  background-color: #e1f0d1;
  border: 1px solid #709e3f;
}
.wrong {
  background-color:#fff9f9
}
.wrong p:first-child {
  color: #cb4949
}
.wrong input[type=radio]:checked + label {
  background-color: #f8e5e5;
  border: 1px solid #cb4949;
}
.correctAnswer {
  background-color: #e1f0d1;
  border: 1px solid #709e3f;
  padding-left:50px;
  margin-bottom: 5px;
}
.correctAnswer label::before{
  content: 'Answer: ';
  margin-left:-50px;
  color: #709e3f;
  font-weight: bold;
  position: absolute;
  font-size: 0.6em;
  vertical-align: middle;
}

.blockScreen__answer {
  display: block;
  margin-bottom: 20px;
  
  color:#cb4949;
}
.correct .hidden {
  display: none;
}

.blockScreen__readingExplain {
  padding: 40px 30px;
  border: 1px solid #000;
  width:calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 60px;
  position:relative;
}
.blockScreen__readingExplainWrapper {
  width: 100%;
  overflow-wrap: break-word;
}
.blockScreen__readingExplainItem{
  margin-bottom: 20px;
  max-width: 700px;
  width:100%;
}
.blockScreen__readingExplainItem p {
  margin-bottom: 10px;
}
.blockScreen__readingExplainItem a::before {
  content: ' ';
}
.blockScreen__readingExplainItem a::after {
  content: ' ';
}
.blockScreen__readingExplainItem span:first-child {
  display: block;
  width: fit-content;
  margin-bottom: 5px;
  padding: 3px 5px;
  background-color: #000;
  color: #fff;
}
.blockScreen__readingExplainImg img{
  max-width: 400px;
  display: block;
  opacity:0.1;
}
.blockScreen__generalQExplainWrapper {
  padding-top: 30px;
}
.blockScreen__generalQExplainWrapper span {
  display: block;
  background-color: #000;
  color: #fff;
  
  width: fit-content;
  padding: 3px 5px;
  margin-bottom: 5px;
}
.blockScreen__generalQExplainWrapper p {
  color: #000;
}
@media only screen and (max-width: 900px){
  .blockScreen__readingExplainImg {
    display: none;
  }
}



/* Help & Trouble shoot section */
.blockScreen__testHelp {
  display: flex;
  background-color: #f2f6fb;
  max-width: 1120px;
  border-radius: 7px;
  margin:0 auto;
  margin-top: 160px;
  margin-bottom:60px;
  padding: 60px 60px;
  align-items: center;
  text-align:center;
}
.blockScreen__testHelp > * {
  width: 100%;
}
.blockScreen__testHelpImg img{
  width:100%;
  display: block;
}
.blockScreen__testHelpText h2 {
  margin-bottom: 5px;
}
.blockScreen__testHelpText p {
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 799px;
}
@media only screen and (max-width: 1280px){
  .blockScreen__testHelp{
    margin: 160px 30px 30px 30px;
    flex-direction: column;
  }
  .blockScreen__testHelpImg img{
    max-width: 400px;
  }
}








.blockScreen__bottomNavMobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .blockScreen__bottomNavMobile {
    display: block;
    position: fixed;
    bottom: 0;
    /* box-shadow: 0px 0px 12px rgb(0 0 0 / 8%); */
    z-index: 999999999999;
    background-color:#1976d2;
    color:#fff;
    text-align: center;
  }
  .blockScreen__bottomMobileProgressWrapper {
    width: 100vw;
    background-color:#f7f7f7;
    border-top:1px solid #1976d2;
    border-bottom: 1px solid #999;
    height: 3px;
  }
  .blockScreen__bottomMobileButtonWrapper hr {
    opacity: 0.2;
    padding:0;
    margin: 0;
  }
  .blockScreen__bottomMobileProgress {
    background-color:#1976d2;
    height: 3px;
    transition-duration: 0.4s;
  }
  .blockScreen__bottomMobileButtonWrapper {
    display: flex;
    justify-content: space-evenly;
  }
  .blockScreen__bottomMobileButton {
    padding: 15px 0 20px 0;
    width:100%;
    line-height:1;
    transition-duration: ease 0.3s;
    font-size: 0.7em;
  }
  .blockScreen__bottomMobileButton.disabled {
    /* background-color:#999; */
    color:#999;
    transition-duration: ease 0.3s;
    pointer-events: none;
  }
  .blockScreen__bottomMobileButton svg {
    padding-bottom: 5px;
  }
}
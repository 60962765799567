.bannerLoggedIn {
  padding: 160px 60px 0 60px;
  margin-bottom: 50px;
  text-align:center;
}
.bannerLoggedIn__text {
  text-transform: capitalize;
}
.bannerLoggedIn__editProfile {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 0;
}
.bannerLoggedIn__editProfile svg {
  margin-right:5px;
}
@media only screen and (max-width:680px){
  .bannerLoggedIn {
    background-size: cover;
    padding: 100px 30px 0 30px;
    margin-bottom: 100px;
  }
}
@media only screen and (max-width:480px){
  .bannerLoggedIn {
    padding: 100px 15px 0 15px;
    margin-bottom: 60px;
  }
}
.torsoView__markOneCardio {
  top: 77px;
  left: 58px;
}
.torsoView__markTwoCardio {
  top: 77px;
  left: 151px;
}
.torsoView__markThreeCardio {
  top: 120px;
  left:117px;
}
.torsoView__markFourCardio {
  top: 150px;
  left: 30px
}
.torsoView__markFiveCardio {
  top: 155px;
  left: 104px;
}
.torsoView__markSixCardio {
  top: 140px;
  left: 160px;
}
.torsoView__markSevenCardio {
  top: 127px;
  left: 190px;
}





@media only screen and (max-width: 680px) {
  .torsoView__markOneCardio {
    top: 57px;
    left: 40px;
  }
  .torsoView__markTwoCardio {
    top: 57px;
    left: 105px;
  }
  .torsoView__markThreeCardio {
    top: 88px;
    left:83px;
  }
  .torsoView__markFourCardio {
    top: 107px;
    left: 19px
  }
  .torsoView__markFiveCardio {
    top: 110px;
    left: 72px;
  }
  .torsoView__markSixCardio {
    top: 102px;
    left: 114px;
  }
  .torsoView__markSevenCardio {
    top: 89px;
    left: 131px;
  }
}
.torsoFemaleView__markOneTrauma {
  top: 125px;
  left: 51px;
}
.torsoFemaleView__markTwoTrauma {
  top: 125px;
  left: 161px;
}
.torsoFemaleView__markThreeTrauma {
  top: 243px;
  left: 104px;
}
.torsoFemaleView__markFourTrauma {
  top: 113px;
  left: 104px
}
.torsoFemaleView__markFiveTrauma {
  top: 43px;
  left: 74px;
}
.torsoFemaleView__markSixTrauma {
  top: 43px;
  left: 138px;
}





@media only screen and (max-width: 680px) {
  .torsoFemaleView__markOneTrauma {
    top: 90px;
    left: 35px;
  }
  .torsoFemaleView__markTwoTrauma {
    top: 90px;
    left: 114px;
  }
  .torsoFemaleView__markThreeTrauma {
    top: 170px;
    left: 75px;
  }
  .torsoFemaleView__markFourTrauma {
    top: 75px;
    left: 75px
  }
  .torsoFemaleView__markFiveTrauma {
    top: 27px;
    left: 54px;
  }
  .torsoFemaleView__markSixTrauma {
    top: 27px;
    left: 98px;
  }
}
.torsoView__markOneAorta {
  top: 107px;
  left: 131px;
}
.torsoView__markThreeAorta {
  top: 123px;
  left:173px;
}
.torsoView__markFourAorta {
  top: 140px;
  left: 106px
}
.torsoView__markSixAorta {
  top: 170px;
  left: 105px;
}
.torsoView__markSevenAorta {
  top: 198px;
  left: 105px;
}
.torsoView__markEightAorta {
  top: 227px;
  left: 104px;
}





@media only screen and (max-width: 680px) {
  .torsoView__markOneAorta {
    top: 77px;
    left: 94px;
  }
  .torsoView__markThreeAorta {
    top: 87px;
    left:127px;
  }
  .torsoView__markFourAorta {
    top: 100px;
    left: 74px
  }
  .torsoView__markSixAorta {
    top: 122px;
    left: 74px;
  }
  .torsoView__markSevenAorta {
    top: 142px;
    left: 74px;
  }
  .torsoView__markEightAorta {
    top: 161px;
    left: 74px;
  }
}